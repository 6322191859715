import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  Chip,
} from "@mui/material";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
  useGetHumanDataVerificationsQuery,
} from "../../../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import AISuggestion from "./AISuggestion";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

interface IContractJurisdictionProps {
  contract: IContract;
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  hideTitle?: boolean;
  documents: IDocument[];
}
function ContractJurisdiction({ ...props }: IContractJurisdictionProps) {
  const { contract, mode, aiOutputs, documents } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Country of jurisdiction")}
        </Typography>
      )}

      <Typography variant="body2">{contract?.countryOfJurisdiction}</Typography>

      {aiOutputs?.length > 0 && (
        <Box my={5}>
          <SimpleTreeView defaultExpandedItems={[`ai-output-items`]}>
            <TreeItem2
              itemId={`ai-output-items`}
              label={t("AI_suggestion", {
                count: aiOutputs?.length,
              })}
              slotProps={{
                content: {
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                  },
                },
              }}
            >
              {aiOutputs?.map((aiOutput) => {
                // if (aiOutput.id === latestAiOutput?.id) {
                //   // skip the latest ai output as it is already shown
                //   return null;
                // }

                const currentResult =
                  aiOutput.metadata?.structuralData?.countryOfJurisdiction;

                if (!currentResult) {
                  return null;
                }

                const verificationRecord =
                  verifications?.find(
                    (v) =>
                      v.field === "countryOfJurisdiction" &&
                      v.aiOutputId === aiOutput.id
                  ) ||
                  verifications?.find(
                    (v) =>
                      v.field === "countryOfJurisdiction" &&
                      v.aiInputId === aiOutput.aiInputId
                  ); // remove this second OR when the backend is fixed;

                const document = documents.find(
                  (d) => d.id === aiOutput.documentId
                );

                return (
                  <TreeItem2
                    itemId={`${aiOutput.id}-prompts`}
                    label={
                      <Typography>
                        {aiOutput.processedAt
                          ? t("date.dateTime", {
                              date: new Date(aiOutput.processedAt),
                            })
                          : "No processing date"}
                      </Typography>
                    }
                    style={{
                      marginBottom: 5,
                      gap: 5,
                    }}
                    slotProps={{
                      content: {
                        style: {
                          paddingTop: 10,
                          paddingBottom: 10,
                        },
                      },
                    }}
                  >
                    <Box my={2} display="flex" flexDirection="column" gap={2}>
                      {document && documents?.length > 1 && (
                        <div>
                          <Chip
                            label={
                              document.type === "attachment"
                                ? document.name
                                : t("Main document")
                            }
                            color="primary"
                            size="small"
                            // icon={<AttachFile />}
                            variant="outlined"
                          />
                        </div>
                      )}

                      {verificationRecord && (
                        <VerifiedAISuggestion
                          verificationRecord={verificationRecord}
                        />
                      )}

                      <AISuggestion
                        contract={contract}
                        mode={mode}
                        isAiSuggestion={!verificationRecord}
                        collapsible={false}
                        isVerified={verificationRecord?.isCorrect !== undefined}
                        field="countryOfJurisdiction"
                        value={currentResult}
                        aiInputId={aiOutput.aiInputId}
                        aiOutputId={aiOutput.id}
                        content={
                          <Typography variant="body1">
                            {currentResult}
                          </Typography>
                        }
                        processedAt={aiOutput?.processedAt}
                      />
                    </Box>
                  </TreeItem2>
                );
              })}
            </TreeItem2>
          </SimpleTreeView>
        </Box>
      )}
    </Box>
  );
}

export default ContractJurisdiction;
