import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  IconButton,
  Button,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  accordionSummaryClasses,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { IContract } from "../../../../types/contract";

import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { NumericFormat } from "react-number-format";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import {
  ArrowDownward,
  ArrowForwardIosSharp,
  Check,
  Edit,
  HowToReg,
  Info,
  SpatialTrackingTwoTone,
  ThumbDown,
  ThumbUp,
  ThumbUpOffAlt,
  Verified,
} from "@mui/icons-material";
import { ThumbsUp } from "react-feather";
import {
  useUpdateContractMutation,
  useUpdateHumanDataVerificationMutation,
} from "../../../../redux/slices/indexApiSlice";
import { HumanDataVerification } from "../../../../types/humanDataVerification";
import ContractPromptResponse from "./ContractPromptResponse";
import HumanDataVerificationDialog from "./HumanDataVerificationDialog";
import { DialogMode } from "../../../../types/dialogmode";
import useAuth from "../../../../hooks/useAuth";

function VerifiedAISuggestion({
  verificationRecord,
}: {
  verificationRecord?: HumanDataVerification;
}) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [updateVerification, { isLoading: isUpdating }] =
    useUpdateHumanDataVerificationMutation();

  if (!verificationRecord) {
    return null;
  }

  return (
    <Alert
      severity={
        verificationRecord?.isCorrect === false
          ? "error"
          : verificationRecord?.isCorrect
          ? "success"
          : "info"
      }
      variant="outlined"
      icon={
        verificationRecord?.isCorrect === false ? (
          <ThumbDown />
        ) : verificationRecord?.isCorrect ? (
          <ThumbUp />
        ) : (
          <Info />
        )
      }
      action={
        <>
          <HumanDataVerificationDialog
            mode={DialogMode.Edit}
            verificationRecord={verificationRecord}
            // onConfirm={(comments) =>
            //   updateVerification({
            //     ...verificationRecord,
            //     comments,
            //     verifiedBy: user.displayName,
            //     verifiedDate: new Date().toUTCString(),
            //   })
            // }
            iconOnly
            showMenuIcon
            disabled={isUpdating}
          />
          <HumanDataVerificationDialog
            mode={DialogMode.Delete}
            verificationRecord={verificationRecord}
            iconOnly
            showMenuIcon
            disabled={isUpdating}
          />
        </>
      }
    >
      {verificationRecord?.comments && (
        <Typography>
          <i>{verificationRecord.comments}</i>
        </Typography>
      )}

      {verificationRecord?.verifiedBy && (
        <Box mt={2}>
          <Typography
            variant="caption"
            color="textDisabled"
            alignItems={"center"}
            display={"flex"}
            sx={{ mt: 1 }}
          >
            {/* <HowToReg fontSize="small" sx={{ mr: 1 }} /> */}

            {t("Verified by", {
              name: verificationRecord.verifiedBy,
              date: new Date(verificationRecord.verifiedDate),
            })}
          </Typography>
        </Box>
      )}
    </Alert>
  );
}

export default VerifiedAISuggestion;
