import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { format, formatRelative, set, sub } from "date-fns";

import {
  Add as AddIcon,
  NoteAdd as NoteAddIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  EditNote as EditNoteIcon,
  Cancel,
  EventAvailable,
  EventBusy,
  Check,
  Savings as SavingsIcon,
  Edit as EditIcon,
  Feed,
  Phone,
  ChevronLeft,
  ChevronRight,
  Mail,
  CrisisAlert,
  Fastfood,
  Event,
  CheckBox,
  NewReleases,
  Verified,
  Launch as LaunchIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Style,
  AutoFixHigh,
  MoreHoriz as MoreHorizIcon,
  HistoryToggleOff,
  History,
  Task,
  Download as DownloadIcon,
  StopCircle,
  AddLocation,
  FmdBad,
  Info,
} from "@mui/icons-material";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  makeStyles,
  Tooltip,
  IconButton,
  Fade,
  CardMedia as MuiCardMedia,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Icon,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepIcon, { StepIconProps } from "@mui/material/StepIcon";

import { fontWeight, spacing, SpacingProps } from "@mui/system";

import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CheckIcon from "@mui/icons-material/Check";
import AlarmIcon from "@mui/icons-material/Alarm";

import { ThemeProps } from "../../types/theme";
import {
  epochToDate,
  useGetActivitiesForContractQuery,
} from "../../redux/slices/indexApiSlice";

import { IContract } from "../../types/contract";
import { useTranslation } from "react-i18next";
import { IActivity } from "../../types/activity";
import { IStep } from "../../types/step";
import { subPeriodFromDate } from "../../utils/string";

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Pagination = styled(MuiPagination)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CircularProgress = styled(MuiCircularProgress)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
  zIndex: 1,
  // color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AddLocation />,
    2: <FmdBad />,
    3: <StopCircle />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

interface ITimelineProgressBarProps {
  contract: IContract;
}
const TimelineProgressBar = ({ contract }: ITimelineProgressBarProps) => {
  const today = new Date();

  const contractStartDate =
    (contract.dates?.startDate && new Date(contract.dates?.startDate)) ||
    new Date(epochToDate(contract.created));
  const contractEndDate =
    (contract.dates?.endDate && new Date(contract.dates?.endDate)) ||
    new Date("2024-12-01");

  const noticeDate = contract.noticePeriod
    ? subPeriodFromDate(contractEndDate, contract.noticePeriod)
    : contractEndDate;
  const renewalPeriodInDays = 45;

  const totalDuration =
    contractStartDate && contractEndDate
      ? contractEndDate.getTime() - contractStartDate.getTime()
      : null;

  const elapsedDuration = contractStartDate
    ? today.getTime() - contractStartDate.getTime()
    : null;

  const noticePoint =
    contractStartDate && totalDuration
      ? (noticeDate.getTime() - contractStartDate.getTime()) / totalDuration
      : null;

  const todayPoint =
    elapsedDuration && totalDuration ? elapsedDuration / totalDuration : null;
  const renewalPeriodPoint = totalDuration
    ? (renewalPeriodInDays * 24 * 60 * 60 * 1000) / totalDuration
    : null;

  const renewalStartPoint =
    renewalPeriodPoint && noticePoint ? noticePoint - renewalPeriodPoint : null;

  const endDateStartPoint =
    renewalPeriodPoint && noticePoint ? noticePoint + renewalPeriodPoint : null;

  // <LinearProgress variant="determinate" value={todayPoint * 100} sx={{ height: 16 }} /> */

  return (
    <Grid pt={2} pb={4}>
      <Grid
        container
        spacing={0}
        gap={0}
        sx={{ height: 15, width: "70%" }}
        margin="0 auto"
        mt={6}
        mb={6}
      >
        <Tooltip
          title={`Created: ${
            contractStartDate && format(contractStartDate, "PP")
          }`}
          open={true}
          enterDelay={500}
          placement="left"
        >
          <Grid
            sx={{ background: (props) => props.palette.primary.main }}
            width={`${todayPoint && todayPoint * 100}%`}
          />
        </Tooltip>

        {today < noticeDate && (
          <Tooltip
            title={`Today: ${format(today, "PP")}`}
            open={true}
            enterDelay={500}
            placement="top-start"
            arrow
          >
            <Grid
              sx={{ background: (props) => props.palette.secondary.main }}
              width={`${
                renewalStartPoint &&
                todayPoint &&
                (renewalStartPoint - todayPoint) * 100
              }%`}
            />
          </Tooltip>
        )}

        <Tooltip
          title={`Negotiation phase: ${renewalPeriodInDays} days`}
          arrow
          open={true}
          enterDelay={500}
          placement="top"
        >
          <Grid
            sx={{ background: (props) => props.palette.grey[400] }}
            width={`${renewalPeriodPoint && renewalPeriodPoint * 100}%`}
          />
        </Tooltip>

        <Tooltip
          title={`Notice date: ${format(noticeDate, "PP")}`}
          arrow
          open={true}
          enterDelay={500}
          placement="bottom"
        >
          <Grid
            sx={{ background: (props) => props.palette.error.main }}
            width={2}
          />
        </Tooltip>

        <Tooltip
          title={`Expiry date: ${format(contractEndDate, "PP")}`}
          open={true}
          enterDelay={500}
          placement="right"
        >
          <Grid
            sx={{ background: (props) => props.palette.error.main }}
            flexGrow={1}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

interface ITimelineStepperProps {
  contract: IContract;
}
const TimelineStepper = ({ contract }: ITimelineStepperProps) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<IStep[] | null>([]);

  useEffect(() => {
    const newSteps = [];

    if (contract.dates?.startDate) {
      const step1: IStep = {
        icon: <AddIcon />,
        label: format(new Date(contract.dates?.startDate), "PP"),
        labelProps: {
          optional: t("Start date"),
        },
        description: "",
        created: new Date(contract.dates?.startDate).getTime(),
      };

      newSteps.push(step1);
    }

    if (contract.noticePeriod) {
      if (!contract.dates?.endDate) {
        const noticeStep: IStep = {
          icon: <Event />,
          label: t("No end date"),
          labelProps: {
            optional: t("Notice period"),
          },
          description: "",
          created: new Date().getTime(),
        };

        newSteps.push(noticeStep);
      } else {
        const contractEndDate = new Date(contract.dates?.endDate);

        const noticeDate = contract.noticePeriod
          ? subPeriodFromDate(contractEndDate, contract.noticePeriod)
          : contractEndDate;

        const description = t("notification_period_date_text", {
          value: contract.noticePeriod?.value ?? 0,
          unit: t(contract.noticePeriod?.unit),
          beforeDate: contract.dates?.endDate
            ? t("date.dateOnly", {
                date: subPeriodFromDate(
                  contract.dates?.endDate,
                  contract.noticePeriod
                ),
              })
            : t("No end date"),
        });

        const noticeStep: IStep = {
          icon: <Event />,
          label: format(noticeDate, "PP"),
          labelProps: {
            optional: t("Latest termination date"),
          },
          description: description,
          created: noticeDate.getTime(),
        };

        newSteps.push(noticeStep);
      }
    }

    if (contract.dates?.endDate) {
      const endDateStep: IStep = {
        icon: <Event />,
        label: format(new Date(contract.dates?.endDate), "PP"),
        labelProps: {
          optional: t("End date"),
        },
        description: "",
        created: new Date(contract.dates?.endDate).getTime(),
      };

      newSteps.push(endDateStep);
    }

    // add a today step
    const today = new Date();
    const todayStep: IStep = {
      icon: <Event />,
      label: format(today, "PP"),
      labelProps: {
        optional: t("Today"),
      },
      description: "",
      created: today.getTime(),
    };

    newSteps.push(todayStep);

    // sort the steps by created date
    newSteps.sort((a, b) => a.created - b.created);

    setSteps(newSteps);
  }, [contract]);

  return (
    <Stepper activeStep={1} orientation="horizontal">
      {steps?.map((step, i) => (
        <Step key={step.label}>
          <StepLabel {...step.labelProps} StepIconComponent={ColorlibStepIcon}>
            {step.label}{" "}
            {step.description && (
              <Tooltip title={step.description} arrow placement="top">
                <Info color="info" fontSize="small" />
              </Tooltip>
            )}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

interface IContractTimelineProps {
  contract: IContract;
}
function ContractTimeline({ ...props }: IContractTimelineProps) {
  const { t } = useTranslation();

  const { data: activities = [], refetch } = useGetActivitiesForContractQuery(
    props.contract.id,
    { skip: !props.contract?.id }
  );

  // const activities: IActivity[] = [];
  // const refetch = () => {};

  // {
  //     id: contract??.owner,
  //   } && { skipToken: !contract??.owner }

  useEffect(() => {
    if (props.contract?.id) {
      refetch();
    }
  }, [props.contract?.id, refetch]);

  return (
    <Grid size={12}>
      {props.contract?.created && props.contract?.dates && (
        <TimelineStepper contract={props.contract} />
      )}
    </Grid>
  );
}

export default ContractTimeline;
