import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper as MuiPaper,
  Skeleton,
  Snackbar,
  SnackbarContent,
  SwipeableDrawer,
  Typography,
  Drawer as MuiDrawer,
  Backdrop,
  AppBar,
  Toolbar,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import { AddAPhoto, Folder, NextWeek } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import Footer from "../components/Footer";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import dashboardItems from "../components/sidebar/dashboardItems";
import useAuth from "../hooks/useAuth";
import {
  hasAccessToken,
  setAccessTokenForTenant,
} from "../redux/slices/authSlice";
import {
  useGetFoldersQuery,
  useGetSettingsQuery,
  useGetSubscriptionInfoQuery,
  useGetTrustedPartnerTrustsQuery,
  useLazyGetAccessTokenForClientQuery,
} from "../redux/slices/indexApiSlice";
import { ITrustedPartnerTrust } from "../types/trustedpartner";
import { SidebarItemsType } from "../types/sidebar";
import AskMeAnythingChatBot from "../pages/components/AskMeAnythingChatBot";
import { useTranslation } from "react-i18next";
import { IGraphUser, IUser } from "../types/user";
import { setUsers as setUsersInStore } from "../redux/slices/usersSlice";
import {
  useLazyGetUsersFromMsTeamQuery,
  useLazyGetUsersQuery,
} from "../redux/slices/graphApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { grey } from "@mui/material/colors";
import { Global } from "@emotion/react";
import InitiateDocumentsDialog from "../components/dialogs/InitiateDocumentsDialog";
import InitiateVendorsDialog from "../components/dialogs/InitiateVendorsDialog";
import NoteDialog from "../components/dialogs/NoteDialog";
import { DialogMode } from "../types/dialogmode";
import AnalyzingContractsLoader from "../components/AnalyzingContractsLoader";

const drawerBleeding = 56;
const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div<{ open?: boolean }>`
  z-index: ${(props) => props.theme.zIndex.drawer};

  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${(props) => (props.open ? drawerWidth : drawerBleeding)}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div<{ skipSideNav?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: absolute;
  top: 60px;
  bottom: 0;
  left: ${(props) => (props.skipSideNav ? 0 : drawerBleeding)}px;
  width: calc(100% - ${(props) => (props.skipSideNav ? 0 : drawerBleeding)}px);
  // height: calc(100vh - 10px);
  overflow: visible;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  display: flex;
  flex-direction: column;
  // flex-grow: 1;
  overflow: auto; // hidden

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    // box-shadow: none;
  }
`;

export enum DisplayModes {
  DASHBOARD = "dashboard",
  MANAGEMENT = "management",
  CONNECTIONS = "connections",
}

interface DashboardType {
  children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardType> = ({ children }) => {
  const router = useLocation();
  const { t } = useTranslation();
  const { user, signOut } = useAuth();
  const theme = useTheme();

  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sidebarItems, setSidebarItems] = useState<
    {
      title: string;
      pages: SidebarItemsType[];
    }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [folderItems, setFolderItems] = useState<SidebarItemsType[]>([]);
  const [trustItems, setTrustItems] = useState<SidebarItemsType[]>([]);
  const navigate = useNavigate();

  // Access the state passed during navigation
  // const reload = router.state && router.state.mode;
  const [mode, setMode] = useState<DisplayModes>(router.state?.mode);
  const [timeRemaining, setTimeRemaining] = useState(10);

  const accessToken = useSelector(
    (state: RootState) => state.auth?.accessToken
  );

  const [getUsers] = useLazyGetUsersQuery();
  const [getUsersFromMsTeam] = useLazyGetUsersFromMsTeamQuery();
  const { data: folders } = useGetFoldersQuery();
  const { data: subscriptionInfo } = useGetSubscriptionInfoQuery();
  const { data: settings } = useGetSettingsQuery();

  // check if there are trusted partner trusts
  const { data: trusts = [], isSuccess } = useGetTrustedPartnerTrustsQuery(
    user.id ?? skipToken
  );

  const [getAccessTokenForClient] = useLazyGetAccessTokenForClientQuery();

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false);

    // also close the drawer
    setOpen(false);
  }, [router.pathname]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    let newSitebarItems: {
      title: string;
      pages: SidebarItemsType[];
    }[] = [...dashboardItems];

    if (folderItems?.length > 0) {
      // add the folders to the Contracts section
      newSitebarItems = newSitebarItems.map((item) => {
        if (item.title === "Contracts") {
          return {
            title: item.title,
            pages: item.pages.map((page) => {
              return {
                ...page,
                children: page.children
                  ? page.children.concat(...folderItems)
                  : folderItems,
              };
            }),
          };
        }

        return item;
      });
    }

    if (trustItems.length > 0) {
      // add the trusts to the Managed Contracts section
      newSitebarItems = newSitebarItems.map((item) => {
        if (item.title === "Managed Contracts") {
          return {
            title: item.title,
            pages: item.pages.map((page) => {
              return {
                ...page,
                hidden: false,
                children: page.children
                  ? page.children.concat(...trustItems)
                  : trustItems,
              };
            }),
          };
        }

        return item;
      });
    }

    setSidebarItems(removeHiddenPages(newSitebarItems));
  }, [folderItems, trustItems]);

  useEffect(() => {
    if (folders && folders?.length > 0) {
      // add the folders to the Contracts section
      const newFolderItems: SidebarItemsType[] = folders.map((folder) => {
        return {
          href: `/contracts/folders/${folder.name}`,
          title: folder.name,
          icon: Folder,
        };
      });

      setFolderItems(newFolderItems);
    }
  }, [folders]);

  useEffect(() => {
    if (isSuccess && accessToken) {
      if (trusts.length > 0) {
        // there are trusts, so show the contracts under management

        console.log("showing contracts under management", trusts);

        const newTrustItems: SidebarItemsType[] = trusts.map(
          (trust: ITrustedPartnerTrust) => {
            return {
              href: `/contracts-under-management/${trust.requestorId}/contracts`,
              title: trust.requestorName,
              icon: NextWeek,
            };
          }
        );

        setTrustItems(newTrustItems);

        // also, retrieve the access token for each trust
        trusts.forEach((trust: ITrustedPartnerTrust) => {
          getAccessTokenForClient(trust.requestorId)
            .unwrap()
            .then((payload) => {
              // send the access token to the store
              dispatch(
                setAccessTokenForTenant({
                  tenantId: trust.requestorId,
                  accessToken: payload,
                })
              );

              setLoading(false);
            });
        });
      } else {
        setLoading(false);
      }
    }
  }, [isSuccess, trusts, accessToken]);

  const removeHiddenPages = (
    arr: any
  ): {
    title: string;
    pages: SidebarItemsType[];
  }[] => {
    const newSidebarItems = arr.map((item: any) => {
      const newPages = item.pages?.filter((page: any) => !page.hidden);

      return {
        title: item.title,
        pages: newPages,
      };
    });

    return newSidebarItems;
  };

  // Recursive function to fetch all users
  const getAllUsers = async (
    nextPage: string,
    arr: IGraphUser[]
  ): Promise<IGraphUser[]> => {
    // Fetch users for the current page
    const { data, error } = await getUsers({
      nextPage,
    });

    // If there's an error, throw it
    if (error) {
      throw new Error(`Failed to fetch users: ${error}`);
    }

    arr.push(...data.value);

    // If there's a next page, recursively fetch users for the next page
    if (data["@odata.nextLink"]) {
      await getAllUsers(data["@odata.nextLink"], arr);
    } else {
      // If there's no next page, return the users from the current page
      return arr || [];
    }

    return arr || [];
  };

  const fetchAllUsers = async () => {
    try {
      const allUsers = await getAllUsers("", []);

      // sort the users
      const usersSorted = allUsers?.sort((a, b) => {
        if (!a.displayName || !b.displayName) return 0;

        return a.displayName.localeCompare(b.displayName);
      });

      // add the users to the store
      dispatch(setUsersInStore(usersSorted));
    } catch (error) {
      console.error("Error fetching all users:", error);
    }
  };

  useEffect(() => {
    if (accessToken && settings) {
      if (settings.microsoftTeamsId) {
        // get users from the MS Teams Team
        getUsersFromMsTeam({
          teamId: settings.microsoftTeamsId,
        }).then((payload) => {
          if (payload.isSuccess) {
            // add the users to the store
            dispatch(setUsersInStore(payload.data));
            setLoading(false);
          } else if (payload.isError) {
            console.error("Error fetching users from MS Teams:", payload.error);
            setLoading(false);
          }
        });
      } else {
        // get all users
        fetchAllUsers();
      }
    }
  }, [accessToken, settings]);

  useEffect(() => {
    if (mode) {
      if (mode === DisplayModes.CONNECTIONS) {
        navigate("/admin/connected-drives", {
          state: { mode: DisplayModes.CONNECTIONS },
        });
      } else if (mode === DisplayModes.DASHBOARD) {
        navigate("/dashboard/analytics", {
          state: { mode: DisplayModes.DASHBOARD },
        });
      } else if (mode === DisplayModes.MANAGEMENT) {
        navigate("/", {
          state: { mode: DisplayModes.MANAGEMENT },
        });
      }
    }
  }, [mode]);

  useEffect(() => {
    if (subscriptionInfo && !subscriptionInfo.isActive) {
      setTimeout(() => {
        countdown();
      }, 1000);
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    if (!subscriptionInfo?.isActive && timeRemaining <= 0) {
      signOut();
    }
  }, [timeRemaining]);

  const countdown = async () => {
    setTimeRemaining((prev) => prev - 1);

    setTimeout(() => {
      countdown();
    }, 1000);
  };

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const container = window !== undefined ? () => document.body : undefined;

  const isUpSm = window.innerHeight > 600;

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: "100%",
            overflow: "visible",
            transition: "all 0.3s ease",
          },
          body: {
            // overflow: isUpSm ? "hidden" : "scroll",
          },
        }}
      />

      <Navbar
        onDrawerToggle={handleDrawerToggle}
        mode={mode}
        setMode={setMode}
      />

      {subscriptionInfo && subscriptionInfo?.isActive !== true && (
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={!subscriptionInfo?.isActive}
        >
          <Alert severity="warning">
            <AlertTitle>{t("Subscription expired")}</AlertTitle>
            {t("Please renew your subscription to continue using the app")}
            <br />
            {t("signOutCountDownMessage", {
              count: timeRemaining,
            })}
          </Alert>
        </Backdrop>
      )}

      <Drawer
        open={open}
        onMouseEnter={toggleDrawer(true)}
        // onBlur={toggleDrawer(false)}
        onMouseLeave={toggleDrawer(false)}
        hidden={
          mode === DisplayModes.CONNECTIONS || mode === DisplayModes.DASHBOARD
        }
      >
        <Toolbar />
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={sidebarItems}
            isDrawerOpen={open}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{
              style: { width: open ? drawerWidth : drawerBleeding },
            }}
            items={sidebarItems}
            isDrawerOpen={open}
          />
        </Box>
      </Drawer>

      {/* z index needs to be 1 higher than the drawer */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={open}
      />

      <AppContent skipSideNav={mode && mode !== DisplayModes.MANAGEMENT}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={loading}
          message={t("Connecting to server")}
          action={<CircularProgress size="1.5rem" color="info" />}
          key={"bottomright"}
          autoHideDuration={5000}
        />

        {!loading && (
          <MainContent py={isLgUp ? 5 : 5} px={2} pl={5}>
            {children}
            <Outlet />
          </MainContent>
        )}

        <Grid container size={12} height={60} />

        <Footer drawerBleeding={drawerBleeding} />
      </AppContent>
      <AskMeAnythingChatBot />
    </Root>
  );
};

export default Dashboard;
