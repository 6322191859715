import { Icon, SvgIcon } from "@mui/material";
import { FaFileWord, FaFilePdf } from "react-icons/fa";

export const FILE_ICONS: { name: string }[] = [
  { name: "accdb" },
  { name: "audio" },
  { name: "code" },
  { name: "csv" },
  { name: "docx" },
  { name: "dotx" },
  { name: "email" },
  { name: "folder" },
  { name: "genericfile" },
  { name: "html" },
  { name: "link" },
  { name: "mpp" },
  { name: "mpt" },
  { name: "model" },
  { name: "one" },
  { name: "onetoc" },
  { name: "potx" },
  { name: "ppsx" },
  { name: "pdf" },
  { name: "photo" },
  { name: "pptx" },
  { name: "presentation" },
  { name: "potx" },
  { name: "pub" },
  { name: "rtf" },
  { name: "spo" },
  { name: "spreadsheet" },
  { name: "txt" },
  { name: "vector" },
  { name: "video" },
  { name: "vsdx" },
  { name: "vssx" },
  { name: "vstx" },
  { name: "xlsx" },
  { name: "xltx" },
  { name: "xsn" },
  { name: "zip" },
];

const getFileIconUrl = (filename: string, fileSizeRaw: number = 1) => {
  let extension = filename.substring(filename?.lastIndexOf(".") + 1);

  if (
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "bmp" ||
    extension === "png" ||
    extension === "tiff" ||
    extension === "gif"
  ) {
    extension = "photo";
  } else if (extension === "url") {
    extension = "link";
  } else if (extension === "doc" || extension === "docm") {
    extension = "docx";
  } else if (extension === "xls" || extension === "xlsm") {
    extension = "xlsx";
  } else if (extension === "ppt" || extension === "pptm") {
    extension = "pptx";
  } else if (extension === "msg") {
    extension = "email";
  } else if (extension === "aspx") {
    extension = "spo";
  } else if (
    extension === "mp4" ||
    extension === "mov" ||
    extension === "wmv" ||
    extension === "flv" ||
    extension === "avi" ||
    extension === "webm" ||
    extension === "mkv"
  ) {
    extension = "video";
  } else if (extension === "mht" || extension === "htm") {
    extension = "html";
  } else if (
    !fileSizeRaw &&
    (!extension || filename.indexOf(".") === -1 || extension === filename)
  ) {
    extension = "folder";
  } else {
    // keep the original extension to look up the icon
  }

  let index = FILE_ICONS.findIndex((el) => el.name === extension);
  if (index === -1) {
    // no icon found, fall back on the genericfile icon
    index = FILE_ICONS.findIndex((el) => el.name === "genericfile");
  }

  if (index > -1) {
    const docType = FILE_ICONS[index];

    const iconUrl = `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${docType.name}.svg`;

    return iconUrl;
  }

  return null;
};

function FileIcon({ ...props }) {
  const extension = props.filename?.substring(
    props.filename?.lastIndexOf(".") + 1
  );

  const iconUrl = getFileIconUrl(props.filename);
  return (
    <>
      {extension && (
        <Icon
          sx={{
            width: props.width || 16,
            height: props.height || 16,
            display: "flex",
            marginLeft: 1,
          }}
        >
          {iconUrl && <img alt={extension} src={iconUrl} />}
        </Icon>
      )}
    </>
  );
}

export default FileIcon;
