import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DocumentViewer from "../../../components/viewers/DocumentViewer";
import useAuth from "../../../hooks/useAuth";
import useContract from "../../../hooks/useContract";
import {
  useGetAIOutputQuery,
  useGetAttachmentsQuery,
  useGetContractQuery,
  useGetContractsQuery,
  useGetDocumentsQuery,
  useGetPromptsQuery,
  useUpdateContractMutation,
} from "../../../redux/slices/indexApiSlice";
import {
  AIReadingStatus,
  ContractStatus,
  IContract,
} from "../../../types/contract";
import { DialogMode } from "../../../types/dialogmode";
import { IDocument } from "../../../types/document";
import { ISignature, ISignee } from "../../../types/signature";
import { IGraphUser } from "../../../types/user";
import AIContractDetailSidebar, {
  SideBarTabIndex,
} from "./components/AIContractDetailSidebar";
import ContractHeader from "./components/ContractHeader";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ILocationInText, IPrompt } from "../../../types/prompt";
import ContractStatusStepper from "./components/ContractStatusStepper";
import AnalyzingContractsLoader from "../../../components/AnalyzingContractsLoader";
import ContractTimeline from "../../components/ContractTimeline";
import {
  ContractExtractionResult,
  Paragraph,
  PromptExtractionResult,
} from "../../../types/aiOutput";
import TabList from "@mui/lab/TabList";
import FileIcon from "../../../icons/FileIcon";
import { Sidebar } from "react-feather";
import { Rnd } from "react-rnd";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const AIContractDetail = ({
  selectedContractId,
  selectedPrompt,
  hideHeader,
}: {
  selectedContractId?: string;
  selectedPrompt?: IPrompt;
  hideHeader?: boolean;
}) => {
  const { t } = useTranslation();
  const { user, method } = useAuth();
  const { clientId, contractId, pageMode, status, folder } = useParams();
  const { requestDocumentAnalysis, analyzedContract, stopDocumentAnalysis } =
    useContract();
  const navigate = useNavigate();

  const users = useSelector((state: RootState) => state.users.users);

  let interval: NodeJS.Timeout | null = null;

  const { data: contracts, isSuccess: contractsLoaded } = useGetContractsQuery(
    status as ContractStatus
  );

  const [sideBarTab, setSideBarTab] = useState(SideBarTabIndex.BASIC_DETAILS);

  const switchToDocument = (doc: IDocument) => {
    if (doc.type === "contract") {
      // select the main document
      // reset the selected prompt
      setPrompt(undefined);
      setParagraph(undefined);
      setLocationInText([]);
      setAiOutput(undefined);

      setDocument(doc);
    } else if (doc.type === "attachment") {
      // select the attachment
      setDocument(doc);
    }
  };

  const {
    data: contract,
    isLoading: contractLoading,
    isSuccess: contractLoaded,
    isError,
  } = useGetContractQuery((contractId || selectedContractId) ?? skipToken);

  // get the documents for the contract
  const { data: documents = [] } = useGetDocumentsQuery(
    (contractId || selectedContractId) ?? skipToken
  );

  const { data: aiOutputs = [] } = useGetAIOutputQuery(contract ?? skipToken);

  const { data: prompts } = useGetPromptsQuery();

  const [mode, setMode] = useState<DialogMode>(DialogMode.ViewOnly);

  const [document, setDocument] = useState<IDocument>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [analysisStatus, setAnalysisStatus] = useState<AIReadingStatus>(
    AIReadingStatus.NONE
  );
  // const [initialContract, setInitialContract] = useState<IContract>();
  const [signer, setSigner] = useState<ISignee>();
  const [signees, setSignees] = useState<Array<ISignee>>([]);
  const [signatures, setSignatures] = useState<Array<ISignature>>([]);
  const [reloadSignatures, setReloadSignatures] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchForText, setSearchForText] = useState<string>("");
  const [prompt, setPrompt] = useState<IPrompt>();

  const [locationInText, setLocationInText] = useState<ILocationInText[]>([]);
  const [promptIdToExpand, setPromptIdToExpand] = useState<string>();
  const [aiOutput, setAiOutput] = useState<ContractExtractionResult>();
  const [paragraph, setParagraph] = useState<Paragraph>();

  // For some reason states (contract, signatures) is not updated
  // in saving functions (handleSaveAsDraft and handleSaveAndPublish).
  // So I use useRef to solve this problem.
  const updatedContractDataRef = useRef<{
    contract: IContract | undefined;
    signatures: ISignature[];
  }>({ contract: undefined, signatures: [] });

  updatedContractDataRef.current = {
    contract: contract ? { ...contract } : undefined,
    signatures: [...signatures],
  };

  useEffect(() => {
    if (documents.length > 0) {
      // select the main contract
      const mainDoc = documents.find((d) => d.type === "contract");
      if (mainDoc) {
        setDocument(mainDoc);
      } else {
        setDocument(documents[0]);
      }
    }
  }, [documents]);

  useEffect(() => {
    if (contract) {
      const newSignees = contract.signatures?.map((sig: ISignature) => {
        const signee: ISignee = {
          displayName: sig.displayName,
          email: sig.email,
          hasSigned: !!sig.signedDate,
          signatureId: sig.id,
          isApproved: sig.isApproved,
        };
        return signee;
      });

      if (newSignees) {
        setSignees(newSignees);
      }

      const newSignatures = contract.signatures?.map((sig: ISignature) => ({
        ...sig,
        draggable: !sig.isSigned,
      }));

      if (newSignatures) {
        setSignatures(newSignatures);
      }

      if (contract.analyzeStatus && contract.analyzeStatus !== analysisStatus) {
        setAnalysisStatus(contract.analyzeStatus);
      }
    }
  }, [contract, reloadSignatures, contractId, selectedContractId]);

  useEffect(() => {
    if (isError) {
      navigate("/404");
    }
  }, [isError]);

  useEffect(() => {
    if (pageMode) {
      if (pageMode === "edit") {
        setMode(DialogMode.Edit);
      }
    }
  }, [pageMode]);

  useEffect(() => {
    if (
      contract?.adminIds?.some((id) => id === user.id) ||
      contract?.managerIds?.some((id) => id === user.id) ||
      contract?.ownerIds?.some((id) => id === user.id)
    ) {
      setMode(DialogMode.Edit);
    } else if (contract?.readerIds?.some((id) => id === user.id)) {
      setMode(DialogMode.ViewOnly);
    } else {
      setMode(DialogMode.ViewOnly);
    }
  }, [
    contract?.adminIds,
    contract?.managerIds,
    contract?.ownerIds,
    contract?.readerIds,
  ]);

  useEffect(() => {
    // hide analysis alert (success or failed)
    if (
      mode === DialogMode.ViewOnly &&
      (analysisStatus === AIReadingStatus.COMPLETED ||
        analysisStatus === AIReadingStatus.ERROR)
    ) {
      setAnalysisStatus(AIReadingStatus.NONE);
    }
  }, [mode]);

  useEffect(() => {
    if (selectedPrompt) {
      setPrompt(selectedPrompt);
    }
  }, [selectedPrompt]);

  useEffect(() => {
    if (promptIdToExpand === "") {
      setPrompt(undefined);
    } else if (promptIdToExpand && !prompt) {
      // a prompt is expanded, but the prompt in this component is empty
      // select the prompt
      const newPrompt = prompts?.find((p) => p.id === promptIdToExpand);
      if (newPrompt) {
        setPrompt(newPrompt);
      }
    } else if (promptIdToExpand) {
      const newPrompt = prompts?.find((p) => p.id === promptIdToExpand);
      if (newPrompt) {
        setPrompt(newPrompt);
      }
    }

    if (promptIdToExpand) {
      // switch to the Fields tab
      setSideBarTab(SideBarTabIndex.FIELDS_FOUND);
    }
  }, [promptIdToExpand]);

  useEffect(() => {
    if (
      !contractId &&
      !selectedContractId &&
      !contractLoading &&
      contracts &&
      contracts?.length > 0
    ) {
      // if no id is provided, redirect to the first contract
      navigate(`/contracts/${contracts[0].id}`);
    }

    if (
      contractId &&
      !selectedContractId &&
      !contractLoading &&
      contracts &&
      contracts?.length > 0
    ) {
      const currentIndex = contracts?.findIndex(
        (c: IContract) => c.id === contractId
      );

      setCurrentIndex(currentIndex);
    }
  }, [contractId, selectedContractId, contractLoading, contracts]);

  useEffect(() => {
    if (contract && contract.signatures) {
      const userHaveSignature = contract.signatures.find(
        (sig: ISignature) => sig.email === user.email
      );
      console.log("userHaveSignature", userHaveSignature);
      if (userHaveSignature) {
        setSigner({
          displayName: user.displayName,
          email: user.email,
          hasSigned: !!userHaveSignature.signedDate,
          signatureId: userHaveSignature.id,
        });
      }
    }
  }, [contract, user]);

  const prev = () => {
    if (contracts && contracts.length > 0) {
      const prevContractId = contracts[currentIndex - 1]?.id || null;

      if (prevContractId) {
        if (clientId) {
          navigate(
            `/contracts-under-management/${clientId}/contract/${prevContractId}/${mode}`
          );
        } else {
          navigate(`/contract/${prevContractId}/${mode}`);
        }
      }
    }
  };

  const next = async () => {
    if (contracts && contracts.length > 0) {
      // get the id of the next contract

      const nextContractId = contracts[currentIndex + 1]?.id || null;

      if (nextContractId) {
        if (clientId) {
          navigate(
            `/contracts-under-management/${clientId}/contract/${nextContractId}/${mode}`
          );
        } else {
          navigate(`/contract/${nextContractId}/${mode}`);
        }
      }
    }
  };

  const handleAnalysis = () => {
    if (document) {
      requestDocumentAnalysis(document);

      setShowSnackbar(true);
    }
  };

  const storeUsers = (): IGraphUser[] => {
    let userStore: IGraphUser[] = [];

    // add the add users to a new contract, so send notification and mail of expired contact.
    if (contract && method !== "Google" && users && users.length) {
      // If method is Google then we stored this user by login step
      let userIds: string[] =
        (contract && contract.ownerIds) || (contract && contract.managerIds)
          ? [...contract?.ownerIds, ...contract?.managerIds]
          : [];

      const userIdsUnique = userIds?.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      userStore = users
        .filter((u: IGraphUser) =>
          userIdsUnique.some((id: string) => id == u.id)
        )
        .map((item: IGraphUser) => {
          item = { ...item, mail: item.mail || item.userPrincipalName };
          return item;
        });
    }

    return userStore;
  };

  const handleClose = () => {
    setShowSnackbar(false);
  };

  const isUpSm = window.innerWidth > 600;

  return (
    <React.Fragment>
      {hideHeader ? null : (
        <>
          <Helmet title={contract?.name} />

          <Grid justifyContent="space-between" container wrap="nowrap">
            <Grid size={12} sx={{ flexGrow: 2 }}>
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/contracts" color="inherit">
                  <Typography variant="h3" gutterBottom display="inline">
                    {t("Contracts")}
                  </Typography>
                </Link>

                {folder && (
                  <Link
                    component={NavLink}
                    to={`/contracts/folders/${folder}`}
                    color="inherit"
                  >
                    <Typography variant="h3" gutterBottom display="inline">
                      {t(folder)}
                    </Typography>
                  </Link>
                )}

                {status && (
                  <Link
                    component={NavLink}
                    to={`/contracts/${status}`}
                    color="inherit"
                  >
                    <Typography variant="h3" gutterBottom display="inline">
                      {t(status)}
                    </Typography>
                  </Link>
                )}

                <Typography
                  variant="h3"
                  gutterBottom
                  display="inline"
                  color="primary"
                >
                  {contract?.name}
                </Typography>
              </Breadcrumbs>
            </Grid>

            {contractsLoaded && (
              <Grid size={{ sm: 12, xl: 2 }}>
                <Grid container justifyContent="end" direction="row" gap={3}>
                  <Grid>
                    <Button
                      onClick={prev}
                      variant="contained"
                      disabled={currentIndex === 0}
                    >
                      <ChevronLeft />
                    </Button>
                  </Grid>

                  <Grid>
                    <Button
                      onClick={next}
                      variant="contained"
                      disabled={currentIndex === contracts?.length - 1}
                    >
                      <ChevronRight />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("Dashboard")}
        </Link>
        <Link component={NavLink} to="/contracts">
          {t("Contracts")}
        </Link>
        <Typography>{contract?.name}</Typography>
      </Breadcrumbs> */}

          <Divider my={6} />

          {contract?.dates && (
            <Box px={6} mb={6} maxWidth="md">
              {/* <ContractStatusStepper contract={contract} /> */}
              <ContractTimeline contract={contract} />
            </Box>
          )}
        </>
      )}

      <Paper
        sx={{
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 4,
          paddingBottom: 4,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          // height: "100%",
          // overflow: isUpSm ? "hidden" : "auto",
        }}
        variant="outlined"
      >
        <Grid container direction="column" gap={3} sx={{ flexGrow: 1 }}>
          {/* {contractLoading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )} */}

          {contractId && !contractLoading && !contract?.id && (
            <Alert severity="error" sx={{ mb: 6 }}>
              <AlertTitle>{t("An error occurred!")}</AlertTitle>
              {t("Contract not found")}
            </Alert>
          )}

          {contract?.id && (
            <ContractHeader contract={contract} documents={documents} />
          )}

          {/* only show completed if modified was within the last 5 minutes */}
          {/* {analysisStatus === AIReadingStatus.COMPLETED &&
          isAfter(
            new Date(epochToDate(contract?.modified)),
            new Date(epochToDate(Date.now() - 300000))
          ) && (
            <Alert severity="success" sx={{ mb: 6 }}>
              <AlertTitle>{t("Success")}</AlertTitle>
              {t("Reading success")}
            </Alert>
          )}
         */}

          {analysisStatus === AIReadingStatus.STOPPED && (
            <Alert severity="info" sx={{ mb: 6 }}>
              <AlertTitle>{t("Stopped")}</AlertTitle>
              {t("Analysis stopped")}
            </Alert>
          )}
          {analysisStatus === AIReadingStatus.ERROR && (
            <Alert severity="error" sx={{ mb: 6 }}>
              <AlertTitle>{t("An error occurred!")}</AlertTitle>
              {t("Reading failed")}
            </Alert>
          )}

          <AnalyzingContractsLoader contract={contract} />

          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="column"
            spacing={3}
            flexGrow={1}
          >
            <Grid container size={12}>
              {documents?.filter((d) => d.type === "attachment").length > 0 && (
                <Grid container gap={2}>
                  <Chip
                    // label={contract?.name}
                    label={t("Main document")}
                    variant={
                      document?.type === "contract" ? "filled" : "outlined"
                    }
                    color={"primary"}
                    onClick={(e) => {
                      const mainDoc = documents.find(
                        (d) => d.type === "contract"
                      );
                      if (mainDoc) {
                        switchToDocument(mainDoc);
                      }
                    }}
                  />
                  {documents
                    ?.filter((d) => d.type === "attachment")
                    ?.map((att, i) => (
                      <Chip
                        label={`${t("Attachment")} ${(i + 1).toString()}: ${
                          att.name
                        }`}
                        variant={
                          att.id === document?.id ? "filled" : "outlined"
                        }
                        color={att.id === document?.id ? "primary" : "default"}
                        onClick={(e) => switchToDocument(att)}
                      />
                    ))}
                </Grid>
              )}

              {/* <Tabs value={tabValue} onChange={handleChange}>
                <Tab
                  label={contract?.name}
                  icon={
                    <FileIcon
                      width={24}
                      height={24}
                      filename={contract?.name}
                    />
                  }
                  iconPosition="start"
                />
                {attachments?.map((att) => (
                  <Tab
                    label={att.name}
                    icon={
                      <FileIcon width={24} height={24} filename={att?.name} />
                    }
                    iconPosition="start"
                  />
                ))}
              </Tabs> */}
            </Grid>

            <Grid container direction="row" size={12} flexGrow={1}>
              <Grid size={{ xs: 12, md: 6 }}>
                {/* {contract && (
              <Box>
                <ContractMetadata contract={contract} />
              </Box>
            )} */}

                <Paper variant="outlined">
                  <DocumentViewer
                    viewMode="withMenu"
                    contract={contract}
                    document={document}
                    aiOutputs={aiOutputs.filter(
                      (a) => a.documentId === document?.id
                    )} // only pass the AI output for the selected document so it will only select those highlight fields
                    signer={signer}
                    signable={false}
                    signatures={signatures}
                    setSignatures={setSignatures}
                    paging={false} // true
                    searchForText={searchForText}
                    prompt={prompt}
                    paragraph={paragraph}
                    locationInText={locationInText}
                    expandPromptInSidebar={(promptId: string) => {
                      setPromptIdToExpand(promptId);
                    }}
                    aiOutput={aiOutput}
                    disableAppBar
                    // disableScroller
                  />
                </Paper>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                {contract && document && (
                  <AIContractDetailSidebar
                    mode={mode}
                    contract={contract}
                    documents={documents}
                    document={document}
                    aiOutputs={aiOutputs}
                    signer={signer}
                    signees={signees}
                    onAnalysis={handleAnalysis}
                    setSignees={setSignees}
                    setSignatures={setSignatures}
                    signatures={signatures}
                    resetSignatures={setReloadSignatures}
                    analysisStatus={analysisStatus}
                    setSearchForText={setSearchForText}
                    promptIdToExpand={promptIdToExpand}
                    handlePromptClick={(p?: IPrompt) => {
                      setPrompt(p);
                    }}
                    setAiOutput={setAiOutput}
                    setSelectedParagraph={setParagraph}
                    sideBarTab={sideBarTab}
                    setSideBarTab={setSideBarTab}
                    switchToDocument={(id: string) => {
                      const doc = documents.find((d) => d.id === id);
                      if (doc) {
                        setDocument(doc);
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showSnackbar}
        onClose={handleClose}
        message={t("Analysis requested")}
        key={"bottomright"}
        autoHideDuration={5000}
        color="primary"
      />
    </React.Fragment>
  );
};

export default AIContractDetail;
