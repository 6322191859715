import styled from "@emotion/styled";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Button as MuiButton,
  Typography,
  Chip as MuiChip,
  Stepper,
  Step,
  StepButton,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ContractStatus, IContract } from "../../../../types/contract";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { green, grey, orange } from "@mui/material/colors";
import { InfoOutlined } from "@mui/icons-material";
import { IDocument } from "../../../../types/document";

const TypographyLight = styled(Typography)`
  color: #646464;
`;
const Chip = styled(MuiChip)<{ color?: string }>`
  height: 24px;
  border-radius: 12px;
  // font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]}; // .light
  color: ${(props) => props.theme.palette.common.white};
`;

type IContractHeaderProps = {
  contract: IContract;
  documents?: IDocument[];
};

const getStatusColor = (status: any) => {
  switch (status) {
    case ContractStatus.DRAFT:
      return grey[500];
    case ContractStatus.PUBLISHED:
      return grey[800];
    case ContractStatus.SIGNING:
      return green[200];
    case ContractStatus.SIGNED:
      return green[500];
    case ContractStatus.ACTIVE:
      return orange[500];
    default:
      return "info";
  }
};

const ContractHeader: React.FC<IContractHeaderProps> = ({
  ...props
}: IContractHeaderProps) => {
  const { t } = useTranslation();
  const { contract, documents } = props;
  const { status } = useParams();

  return (
    <Box>
      <Grid container display="flex" justifyContent="flex-end" width="100%">
        <Tooltip
          title={
            <Grid container direction="column" gap={3}>
              <Typography variant="caption">
                {t("Contract ID")}: {contract?.id}
              </Typography>

              {documents?.map((doc) => (
                <Typography variant="caption" key={doc.id}>
                  {doc.name}
                  <br />
                  {doc.id}
                </Typography>
              ))}
            </Grid>
          }
        >
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Grid>
    </Box>
  );
};

export default ContractHeader;
