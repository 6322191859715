import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  Alert,
  AlertTitle,
  Chip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { MoreHoriz as MoreHorizIcon, Person } from "@mui/icons-material";

import { IContract } from "../../../../types/contract";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { DialogMode } from "../../../../types/dialogmode";
import { useGetHumanDataVerificationsQuery } from "../../../../redux/slices/indexApiSlice";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import AISuggestion from "./AISuggestion";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { IDocument } from "../../../../types/document";

const ContactPersonCard = ({
  name,
  email,
  phone,
  role,
}: {
  name: string;
  email: string;
  phone: string;
  role: string;
}) => {
  return (
    <Alert
      severity="info"
      icon={<Person />}
      variant="outlined"
      sx={{ minWidth: 200 }}
    >
      <Typography variant="body1">{name}</Typography>
      <Typography variant="body1">{email}</Typography>
      <Typography variant="body1">{phone}</Typography>
      <Typography variant="body1">{role}</Typography>
    </Alert>
  );
};

interface IContractContactPersonsProps {
  contract: IContract;
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  hideTitle?: boolean;
  documents: IDocument[];
}
function ContractContactPersons({ ...props }: IContractContactPersonsProps) {
  const { contract, mode, aiOutputs, documents } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Contact persons")}
        </Typography>
      )}

      <div>
        {contract?.buyer && contract.buyer.contactPersons?.length > 0 && (
          <Box mb={3}>
            <Grid container spacing={3} pt={2}>
              {contract.buyer.contactPersons.map((person, i) => {
                return (
                  <Grid key={`contactPerson-${i}-${person.email}`}>
                    <ContactPersonCard {...person} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}

        {contract?.seller && contract.seller.contactPersons?.length > 0 && (
          <Box mb={3}>
            <Grid container spacing={3} pt={2}>
              {contract.seller.contactPersons.map((person, i) => {
                return (
                  <Grid key={`contactPerson-${i}-${person.email}`}>
                    <ContactPersonCard {...person} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
      </div>

      {aiOutputs?.length > 0 && (
        <Box my={5}>
          <SimpleTreeView defaultExpandedItems={[`ai-output-items`]}>
            <TreeItem2
              itemId={`ai-output-items`}
              label={t("AI_suggestion", {
                count: aiOutputs?.length,
              })}
              slotProps={{
                content: {
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                  },
                },
              }}
            >
              {aiOutputs?.map((aiOutput) => {
                // if (aiOutput.id === latestAiOutput?.id) {
                //   // skip the latest ai output as it is already shown
                //   return null;
                // }

                const currentResult =
                  aiOutput.metadata?.structuralData?.summary;

                if (!currentResult) {
                  return null;
                }

                const verificationRecord = verifications?.find(
                  (v) => v.field === "buyer" && v.aiOutputId === aiOutput.id
                );

                const document = documents.find(
                  (d) => d.id === aiOutput.documentId
                );

                return (
                  <TreeItem2
                    itemId={`${aiOutput.id}-prompts`}
                    label={
                      <Typography>
                        {aiOutput.processedAt
                          ? t("date.dateTime", {
                              date: new Date(aiOutput.processedAt),
                            })
                          : "No processing date"}
                      </Typography>
                    }
                    style={{
                      marginBottom: 5,
                      gap: 5,
                    }}
                    slotProps={{
                      content: {
                        style: {
                          paddingTop: 10,
                          paddingBottom: 10,
                        },
                      },
                    }}
                  >
                    <Box my={2} display="flex" flexDirection="column" gap={2}>
                      {document && documents?.length > 1 && (
                        <div>
                          <Chip
                            label={
                              document.type === "attachment"
                                ? document.name
                                : t("Main document")
                            }
                            color="primary"
                            size="small"
                            // icon={<AttachFile />}
                            variant="outlined"
                          />
                        </div>
                      )}

                      {verificationRecord && (
                        <VerifiedAISuggestion
                          verificationRecord={verificationRecord}
                        />
                      )}

                      <AISuggestion
                        contract={contract}
                        mode={mode}
                        isAiSuggestion={!verificationRecord}
                        isVerified={verificationRecord?.isCorrect !== undefined}
                        field="buyer"
                        value={aiOutput?.metadata?.structuralData?.buyer}
                        field2="seller"
                        value2={aiOutput?.metadata?.structuralData?.seller}
                        aiInputId={aiOutput.aiInputId}
                        aiOutputId={aiOutput.id}
                        content={
                          <div>
                            {aiOutput.metadata?.structuralData?.buyer &&
                              aiOutput.metadata?.structuralData?.buyer
                                .contactPersons?.length > 0 && (
                                <Box mb={3}>
                                  <Grid container spacing={3} pt={2}>
                                    {aiOutput.metadata?.structuralData?.buyer.contactPersons.map(
                                      (person: any, i: number) => {
                                        return (
                                          <Grid
                                            key={`contactPerson-${i}-${person.email}`}
                                          >
                                            <ContactPersonCard {...person} />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                </Box>
                              )}

                            {aiOutput.metadata?.structuralData?.seller &&
                              aiOutput.metadata?.structuralData?.seller
                                .contactPersons?.length > 0 && (
                                <Box mb={3}>
                                  <Grid container spacing={3} pt={2}>
                                    {aiOutput.metadata?.structuralData?.seller.contactPersons.map(
                                      (person: any, i: number) => {
                                        return (
                                          <Grid
                                            key={`contactPerson-${i}-${person.email}`}
                                          >
                                            <ContactPersonCard {...person} />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                </Box>
                              )}
                          </div>
                        }
                        processedAt={aiOutput?.processedAt}
                      />
                    </Box>
                  </TreeItem2>
                );
              })}
            </TreeItem2>
          </SimpleTreeView>
        </Box>
      )}
    </Box>
  );
}

export default ContractContactPersons;
