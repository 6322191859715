import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { Eye } from "react-feather";
import {
  Add as AddIcon,
  NoteAdd as NoteAddIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  EditNote as EditNoteIcon,
  Cancel,
  EventAvailable,
  EventBusy,
  Check,
  Savings as SavingsIcon,
  Edit as EditIcon,
  Eject as EjectIcon,
  Launch as LaunchIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Style,
  AutoFixHigh,
  MoreHoriz as MoreHorizIcon,
  HistoryToggleOff,
  History,
  Task,
  Download as DownloadIcon,
  AutoFixOff,
  CancelOutlined,
  Recycling,
  Remove,
} from "@mui/icons-material";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Container,
  Pagination as MuiPagination,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  makeStyles,
  Tooltip,
  IconButton,
  TextField as MuiTextField,
  Alert as MuiAlert,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";

import { fontWeight, spacing, SpacingProps } from "@mui/system";

import {
  useDeleteDocumentMutation,
  useGetDocumentContentQuery,
  useLazyGetDocumentContentQuery,
} from "../../../redux/slices/indexApiSlice";
import {
  useGetDriveItemQuery,
  useGetDriveItemVersionsQuery,
  useLazyGetDriveItemContentAsPDFQuery,
  useLazyGetDriveItemContentQuery,
} from "../../../redux/slices/graphApiSlice";
import { IDocument } from "../../../types/document";

import FileIcon from "../../../icons/FileIcon";

import { useTranslation } from "react-i18next";

import { saveAs } from "file-saver";
import { formatBytes } from "../../../utils/string";
import { IDriveItem } from "../../../types/driveItem";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { IOutlookMessageAttachment } from "../../../types/outlookattachment";

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Pagination = styled(MuiPagination)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Title = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.8;
  text-transform: uppercase;
  display: block;
`;

type IDocumentAsListItemProps = {
  document: IDocument;
  loadCurrentVersionContent: (document: IDocument) => void;
  loadDocumentVersionContent: (document: IDocument, versionId: string) => void;
  onClick: () => void;
  onDelete?: () => void;
  setDeletedDocumentId: (id: string) => void;
  isActive?: boolean;
};

const DocumentAsListItem = (props: IDocumentAsListItemProps) => {
  const { t } = useTranslation();

  const {
    document,
    loadCurrentVersionContent,
    loadDocumentVersionContent,
    onClick,
    onDelete,
  } = props;

  const [isDisabled, setIsDisabled] = useState(false);

  const [getDocumentContent, { data: arrayBuffer }] =
    useLazyGetDocumentContentQuery();

  const { data: driveItem } = useGetDriveItemQuery(
    { driveId: document?.driveId, driveItemId: document?.driveItemId },
    { skip: !document?.driveId || !document?.driveItemId }
  );

  const { data: driveItemVersions = [] } = useGetDriveItemVersionsQuery(
    { driveId: document?.driveId, driveItemId: document?.driveItemId },
    { skip: !document?.driveId || !document?.driveItemId }
  );

  const [
    deleteDocument,
    { isLoading: isDeleting, isSuccess: documentDeleted },
  ] = useDeleteDocumentMutation();

  useEffect(() => {
    if (documentDeleted && document.id) {
      // get the index of the current maindocument (the document that was just deleted)
      props.setDeletedDocumentId(document.id);

      props.onDelete && props.onDelete();

      handleCloseMenu();
    }
  }, [documentDeleted]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleVersionClick = (versionId: string, i: number) => {
    if (i === 0) {
      loadCurrentVersionContent(document);
    } else {
      loadDocumentVersionContent(document, versionId);
    }
  };

  const handleDeleteClick = () => {
    setIsDisabled(true);
    if (props.onDelete) {
      props.onDelete();
    } else {
      if (document) {
        deleteDocument(document);
      }
    }
  };

  const openContextMenu = (e: any) => {
    e.stopPropagation();

    handleOpenMenu(e);
  };

  const viewDocument = async () => {
    console.log("viewDocument", document);

    if (document?.id && document?.location === "blobstorage") {
      // the document is stored in our blob storage
      const response = await getDocumentContent(document);

      if (response.isSuccess) {
        const mimeType = "application/pdf"; // MIME type of the file
        const arrayBuffer = response.data;
        const blob = new Blob([new Uint8Array((arrayBuffer as any).data)], {
          type: mimeType,
        });

        const fileURL = URL.createObjectURL(blob);

        window.open(fileURL, "_blank", "toolbar=no,location=no,menubar=no");
      }
    } else if (document?.origin === "outlook") {
      viewOutlookFile(document as any);
    }
  };

  const viewOutlookFile = async (attachment: IOutlookMessageAttachment) => {
    const binaryString = window.atob(attachment.contentBytes);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes]);
    const outlookFile = new File([blob], attachment.name, {
      type: attachment.contentType,
    });

    const contentUrl = URL.createObjectURL(outlookFile);
    window.open(contentUrl, "_blank", "toolbar=no,location=no,menubar=no");
  };

  const openInSharePoint = () => {
    if (!driveItem?.webUrl) return;

    window.open(
      `${driveItem?.webUrl}?web=1`,
      "_blank",
      "toolbar=no,location=no,menubar=no"
    );
  };

  const downloadFromSharePoint = () => {
    if (!driveItem["@microsoft.graph.downloadUrl"]) return;

    saveAs(driveItem["@microsoft.graph.downloadUrl"], document?.name);
  };

  return (
    <ListItem
      color="secondary"
      secondaryAction={
        <>
          {driveItem ? (
            <>
              <IconButton
                key="menu-item-sharepoint"
                onClick={openInSharePoint}
                title={t("Open in SharePoint")!}
                color="info"
              >
                <Eye />
              </IconButton>

              <IconButton
                key="menu-item-download-document"
                onClick={downloadFromSharePoint}
                color="info"
                title={t("Download")!}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                onClick={() => viewDocument()}
                title={t("Open")!}
                color="info"
                size="small"
              >
                <Eye />
              </IconButton>
              <IconButton
                //  onClick={() => saveAs(file, file.name)}
                title={t("Download")!}
                color="info"
                size="small"
              >
                <DownloadIcon />
              </IconButton>
            </>
          )}

          {document?.origin === "sharepoint" && document?.id && (
            <IconButton
              key="menu-item-unlink-document"
              onClick={handleDeleteClick}
              disableRipple
            >
              <ListItemIcon>
                <EjectIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("Unlink")}</ListItemText>
            </IconButton>
          )}

          {document?.driveId && document?.id ? (
            <>
              <Title>{t("Versions")}</Title>
              {driveItemVersions?.map((v: any, i: number) => {
                return (
                  <IconButton
                    key={`version-${v.id}`}
                    onClick={() => handleVersionClick(v.id, i)}
                    disableRipple
                  >
                    <ListItemIcon>
                      {i === 0 ? <Check /> : <HistoryToggleOff />}
                    </ListItemIcon>
                    <ListItemText>
                      {" "}
                      {v.id} {i === 0 && "(current)"}
                      {/* {v.lastModifiedDateTime} */}
                    </ListItemText>
                  </IconButton>
                );
              })}

              {onDelete && (
                <IconButton
                  onClick={handleDeleteClick}
                  title={t("Delete")!}
                  color="info"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </>
          ) : onDelete ? (
            <IconButton
              onClick={handleDeleteClick}
              title={t("Delete")!}
              color="info"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <span />
          )}
        </>
      }
      disablePadding
    >
      <ListItemButton onClick={onClick} disabled={isDisabled}>
        {/* 48px for each button */}
        <ListItemIcon>
          <FileIcon filename={document?.name} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={document?.name}
            >
              {document?.name}
            </Typography>
          }
          secondary={
            document.size && (
              <Typography>{formatBytes(document.size)}</Typography>
            )
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default DocumentAsListItem;
