import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Chip as MuiChip,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Toolbar,
  Tooltip,
  Typography,
  Alert,
  AlertTitle,
  LinearProgress,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  Description,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Delete as DeleteIcon,
  Cancel,
  Edit as EditIcon,
} from "@mui/icons-material";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridState,
  gridPaginationModelSelector,
  useGridApiRef,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { IGraphUser } from "../../types/user";
import AzureADAvatar from "../avatars/AzureADAvatar";
import {
  useDeleteContractMutation,
  useGetContractsQuery,
  useGetFoldersQuery,
} from "../../redux/slices/indexApiSlice";
import { Delete, LegendToggle } from "@mui/icons-material";
import { format, set } from "date-fns";
import VendorAvatar from "../avatars/VendorAvatar";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { Check, Star } from "react-feather";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import EnhancedDataGridToolbar from "./EnhancedDataGridToolbar";
import ContractDialog from "../dialogs/ContractDialog";
import { DialogMode } from "../../types/dialogmode";
import { ContractStatus, IContract } from "../../types/contract";
import { useGetDriveItemsQuery } from "../../redux/slices/graphApiSlice";
import { AIReadingStatus } from "../../types/contract";
import useContract from "../../hooks/useContract";
import AnalyzingContractsLoader from "../AnalyzingContractsLoader";
import { useTheme } from "@mui/material/styles";
import { IVendor } from "../../types/vendor";
import { enGB, nl } from "date-fns/locale";
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 24px;
  border-radius: 12px;
  // font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]}; // .light
  // color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

interface IDataGridContractsProps {
  managed?: boolean;
  selected?: string[];
  onSelect?: (model: GridRowSelectionModel) => void;
  view?: "cards" | "table";

  // Move get contracts query outside this comp to re-use to show vendor's contracts
  // These are 4 props of getContractsQuery
  vendor?: IVendor;
  vendorContracts?: IContract[];
  vendorContractsLoaded?: boolean;
  vendorContractsLoading?: boolean;
}

function DataGridContracts({ ...props }: IDataGridContractsProps) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { status, folder } = useParams();

  const { analyzedContract } = useContract();

  const apiRef = useGridApiRef();

  const [contracts, setContracts] = useState<IContract[]>([]);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [searchText, setSearchText] = useState<string>("");

  const {
    data: contractsAPI = [],
    isLoading: contractsLoading,
    isSuccess: contractsLoaded,
    isUninitialized,
    isFetching,
    refetch,
  } = useGetContractsQuery(status as ContractStatus);

  const [deleteContract, { isLoading: deleting, isSuccess: isDeleted }] =
    useDeleteContractMutation();

  const { data: folders } = useGetFoldersQuery();

  const [title, setTitle] = useState<string>("");

  const columns: GridColDef[] = [
    {
      flex: 1,
      field: "name",
      headerName: t("Name"),
      disableColumnMenu: true,
      cellClassName: "name-column--cell",
      headerClassName: "white-header",
      renderCell: (cellValues: any) => {
        return (
          <Tooltip title={cellValues.row?.name} placement="top">
            <Typography
              variant="body2"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {/* {cellValues.row?.analyzeStatus === ReadingStatus.READING && (
                <Chip
                  sx={{ mr: 2 }}
                  label={t("Analyzing")}
                  color="primary"
                  variant="outlined"
                />
              )} */}

              {cellValues.row?.analyzeStatus === AIReadingStatus.ANALYZING && (
                <Chip
                  sx={{ mr: 2 }}
                  label={t("Reading document")}
                  color="primary"
                  variant="outlined"
                />
              )}

              {cellValues?.row?.name}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      flex: 1,
      field: "vendorName",
      headerName: t("Vendor"),
      disableColumnMenu: true,
      headerClassName: "white-header",
      // renderCell: (cellValues: any) => {
      //   return (
      // cellValues?.row?.vendorId && (
      //   <VendorAvatar vendorId={cellValues?.row?.vendorId} small />
      // )
      //   );
      // },
    },
    {
      flex: 1,
      field: "type",
      headerName: t("Type"),
      disableColumnMenu: true,
      valueFormatter: (params: any) => {
        return t(params);
      },
    },
    {
      flex: 1,
      field: "category",
      headerName: t("Category"),
      disableColumnMenu: true,
    },
    {
      flex: 1,
      field: "summary",
      headerName: t("Summary"),
      disableColumnMenu: true,
      // multi line
      renderCell: (cellValues: any) => {
        return (
          <Tooltip title={cellValues.row?.summary} placement="bottom">
            <Typography
              variant="body2"
              fontSize={11}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                textWrap: "wrap",
              }}
            >
              {cellValues?.row?.summary}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "startDate",
      headerName: t("Start date")!,
      disableColumnMenu: true,
      // renderCell: (cellValues: any) => {
      //   return cellValues.row?.startDate && format(new Date(cellValues.row?.startDate), "P");
      // },
      valueFormatter: (params: any) => {
        return (
          params &&
          format(new Date(params), "PP", {
            locale: i18n.language === "nl" ? nl : enGB,
          })
        );
      },
    },
    {
      field: "endDate",
      headerName: t("End date")!,
      disableColumnMenu: true,
      // renderCell: (cellValues: any) => {
      //   return cellValues.row?.endDate && format(new Date(cellValues.row?.endDate), "P");
      // },
      valueFormatter: (params: any) => {
        return (
          params &&
          format(new Date(params), "PP", {
            locale: i18n.language === "nl" ? nl : enGB,
          })
        );
      },
    },
    {
      field: "budget",
      headerName: t("Value")!,
      disableColumnMenu: true,
      // renderCell: (cellValues: any) => {
      //   const formattedCurrency = new Intl.NumberFormat(navigator.language, {
      //     style: "currency",
      //     currency: "EUR", // Replace with your desired currency code
      //     compactDisplay: "long",
      //     maximumFractionDigits: 0,
      //   }).format(cellValues.row?.value); // Format the number as currency

      //   return cellValues.row?.value && formattedCurrency;
      // },
      valueFormatter: (params: any) => {
        if (params?.totalValue) {
          const formattedCurrency = new Intl.NumberFormat(navigator.language, {
            style: "currency",
            currency: params?.currency || "EUR",
            compactDisplay: "long",
            maximumFractionDigits: 0,
          }).format(params?.totalValue); // Format the number as currency

          return formattedCurrency;
        }

        if (params?.amount) {
          const formattedCurrency = new Intl.NumberFormat(navigator.language, {
            style: "currency",
            currency: params?.currency || "EUR",
            compactDisplay: "long",
            maximumFractionDigits: 0,
          }).format(params?.amount); // Format the number as currency

          return formattedCurrency;
        }

        return "";
      },
    },
    {
      field: "adminIds",
      headerName: t("Administrator")!,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <AvatarGroup
            max={3}
            slotProps={{
              additionalAvatar: {
                sx: {
                  width: 32,
                  height: 32,
                  fontSize: "small",
                  fontWeight: "bold",
                },
              },
            }}
          >
            {cellValues.row?.adminIds?.map((userId: string) => {
              return (
                <AzureADAvatar
                  key={`azuread-avatar-${userId}`}
                  userId={userId}
                  pictureOnly
                />
              );
            })}
          </AvatarGroup>

          // <Grid container gap={1} direction="row">
          //   {cellValues.row?.adminIds?.map((userId: string) => (
          //     <Grid key={`azuread-avatar-${userId}`}>
          //       <AzureADAvatar
          //         key={`azuread-avatar-${userId}`}
          //         userId={userId}
          //         pictureOnly
          //       />
          //     </Grid>
          //   ))}
          // </Grid>
        );
      },
    },
    {
      field: "managerIds",
      headerName: t("Manager")!,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <AvatarGroup
            max={3}
            slotProps={{
              additionalAvatar: {
                sx: {
                  width: 32,
                  height: 32,
                  fontSize: "small",
                  fontWeight: "bold",
                },
              },
            }}
          >
            {cellValues.row?.managerIds?.map((userId: string) => {
              return (
                <AzureADAvatar
                  key={`azuread-avatar-${userId}`}
                  userId={userId}
                  pictureOnly
                />
              );
            })}
          </AvatarGroup>

          // <Grid container gap={1} direction="row">
          //   {cellValues.row?.managerIds?.map((userId: string) => (
          //     <Grid key={`azuread-avatar-${userId}`}>
          //       <AzureADAvatar
          //         key={`azuread-avatar-${userId}`}
          //         userId={userId}
          //         pictureOnly
          //       />
          //     </Grid>
          //   ))}
          // </Grid>
        );
      },
    },
    {
      field: "ownerIds",
      headerName: t("Owner")!,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <AvatarGroup
            max={3}
            slotProps={{
              additionalAvatar: {
                sx: {
                  width: 32,
                  height: 32,
                  fontSize: "small",
                  fontWeight: "bold",
                },
              },
            }}
          >
            {cellValues.row?.ownerIds?.map((userId: string) => {
              return (
                <AzureADAvatar
                  key={`azuread-avatar-${userId}`}
                  userId={userId}
                  pictureOnly
                />
              );
            })}
          </AvatarGroup>

          // <Grid container gap={1} direction="row">
          //   {cellValues.row?.ownerIds?.map((userId: string) => (
          //     <Grid key={`azuread-avatar-${userId}`}>
          //       <AzureADAvatar userId={userId} pictureOnly />
          //     </Grid>
          //   ))}
          // </Grid>
        );
      },
    },
    // {
    //   flex: 1,
    //   field: "assignees",
    //   headerName: t("Assignees")!,
    //   disableColumnMenu: true,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       cellValues?.row?.assigneeIds?.length > 0 && (
    //         <AvatarGroup total={cellValues.row.assigneeIds.length}>
    //           {cellValues.row.assigneeIds?.map((assigneeId: string) => {
    //             return <AzureADAvatar key={`azure-ad-avatar-${assigneeId}`} userId={assigneeId} small={cellValues.row.assigneeIds.length === 1} />;
    //           })}
    //         </AvatarGroup>
    //       )
    //     );
    //   },
    // },
    {
      field: "tenantId",
      headerName: t("Organization")!,
      disableColumnMenu: true,
    },
    {
      // flex: 1,
      field: "status",
      headerName: t("Status")!,
      disableColumnMenu: true,
      renderCell: (cellValues: any) => {
        return (
          <Chip
            label={t(cellValues.row?.status)}
            color={
              cellValues.row?.status === ContractStatus.PUBLISHED
                ? "success"
                : "info"
            }
          />
        );
      },
    },
    {
      headerName: t("Action")!,
      minWidth: 140,
      field: "action",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues: any) => {
        return (
          <Grid container gap={1} direction="row" wrap="nowrap">
            <Grid>
              <IconButton
                size="small"
                aria-label="view"
                onClick={(event) => {
                  handleViewDetails(event, cellValues);
                }}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            </Grid>

            {/* <Grid>
              <IconButton
                size="small"
                aria-label="view"
                onClick={(event) => {
                  handleEditDetails(event, cellValues);
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid> */}

            {!props.managed && (
              <Grid>
                <ContractDialog
                  contract={cellValues.row}
                  mode={DialogMode.Delete}
                  iconOnly
                />
              </Grid>
            )}
          </Grid>
        );
      },
    },
  ].filter((column: GridColDef) => {
    if (!props.managed && column.field === "tenantId") {
      return false;
    }

    return true;
  });

  // useEffect(() => {
  //   // only show contracts where I am the owner
  //   const myContracts = contracts?.filter((c: IContract) =>
  //     c.ownerIds?.includes(user.id)
  //   );

  //   setContracts(myContracts);
  // }, [contracts]);

  useEffect(() => {
    if (props.vendor && props.vendorContracts) {
      setContracts(props.vendorContracts);
    }
  }, [props.vendorContracts, props.vendor]);

  useEffect(() => {
    if (!props.vendor && contractsLoaded && !contractsLoading) {
      if (folder && folders) {
        const folderId = folders?.find((f) => f.name === folder)?.id;

        if (folderId) {
          const contractsInFolder = contractsAPI.filter((c: IContract) =>
            c.folderIds?.includes(folderId)
          );

          setContracts(contractsInFolder);
        } else {
          setContracts([]);
        }
      } else {
        setContracts(contractsAPI);
      }
    }
  }, [
    props.vendor,
    contractsLoaded,
    contractsLoading,
    contractsAPI,
    folder,
    folders,
  ]);

  useEffect(() => {
    if (analyzedContract) {
      setContracts((prevContracts) =>
        prevContracts.map((c) => {
          if (c.id === analyzedContract.id) {
            return { ...analyzedContract };
          } else {
            return c;
          }
        })
      );
    }
  }, [analyzedContract]);

  useEffect(() => {
    if (props?.selected) {
      // Set the default selected items
      setRowSelectionModel(props.selected);
    }
  }, []);

  useEffect(() => {
    if (contractsLoaded || props.vendorContractsLoaded) {
      const p = contracts?.filter(
        (c: IContract) => c.status === ContractStatus.PUBLISHED
      );

      const d = contracts?.filter(
        (c: IContract) => c.status !== ContractStatus.PUBLISHED
      );

      const pText = p.length === 1 ? t("published") : t("published");
      const draftsText = d.length === 1 ? t("draft") : t("drafts");

      const els = [];
      if (p.length > 0) {
        els.push(`${p?.length} ${pText}`);
      }

      if (d.length > 0) {
        els.push(`${d?.length} ${draftsText}`);
      }

      const newTitle = els.join(", ").toLowerCase();
      setTitle(newTitle);
    }
  }, [contracts, contractsLoaded, props.vendorContractsLoaded]);

  useEffect(() => {
    if (contractsLoaded || props.vendorContractsLoaded) {
      props?.onSelect && props?.onSelect(rowSelectionModel);
    }
  }, [rowSelectionModel, contractsLoaded, props.vendorContractsLoaded]);

  const navigate = useNavigate();

  const handleRowDoubleClick = (params: any) => {
    goToContract(params.row.id);
  };

  const handleViewDetails = (event: any, cellValues: any) => {
    // stop the row selection event from triggering
    event.stopPropagation();

    goToContract(cellValues.row.id);
  };

  const goToContract = (contractId: string) => {
    let url: string;
    if (folder) {
      // keep the folder in the url
      url = `/${
        props.managed ? "managed-" : ""
      }contracts/folders/${folder}/${contractId}`;
    } else {
      url = `/${props.managed ? "managed-" : ""}contract/${contractId}`;
    }

    navigate(url, { state: { reload: true } });
  };

  const handleEditDetails = (event: any, cellValues: any) => {
    // stop the row selection event from triggering
    event.stopPropagation();

    navigate(
      `/${props.managed ? "managed-" : ""}contract/${cellValues.row.id}/${
        DialogMode.Edit
      }`,
      { state: { reload: true } }
    );
  };

  // This function is triggered when the delete button in the toolbar is clicked
  const handleToolbarDeleteClick = (event: any) => {
    console.log(rowSelectionModel);

    rowSelectionModel.forEach((row) => {
      deleteContract(row.toString());
    });

    refetch();
  };

  const isUpXxl = useMediaQuery(theme.breakpoints.up(1920));
  const isUpXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isUpLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const isUpSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Paper variant="outlined">
      <Box
        // height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
        }}
      >
        <Paper p={4}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid size={12}>
              <TextField // Search field
                variant="outlined"
                label={t("Search")}
                fullWidth
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid size={12}>
              <AnalyzingContractsLoader />
            </Grid>
          </Grid>
        </Paper>

        {(contractsLoading || props.vendorContractsLoading) && (
          <CircularProgress sx={{ margin: 4 }} />
        )}
        {(contractsLoaded || props.vendorContractsLoaded) &&
          contracts?.length === 0 && (
            <Box p={6}>
              <CustomNoRowsOverlay label={t("No contracts")} />
            </Box>
          )}

        {!contractsLoading &&
          !props.vendorContractsLoading &&
          (contractsLoaded || props.vendorContractsLoaded) &&
          contracts?.length > 0 && (
            <DataGrid
              initialState={{
                pagination: { paginationModel: { page: 0, pageSize: 25 } },
                columns: {
                  columnVisibilityModel: {
                    adminIds: isUpXl,
                    managerIds: isUpXl,
                    ownerIds: isUpXl,
                    status: isUpLg,
                    summary: isUpLg,
                    category: isUpXxl,
                    type: isUpXxl,
                    startDate: isUpXxl,
                    endDate: isUpLg,
                    budget: isUpLg,
                  },
                },
              }}
              apiRef={apiRef}
              pageSizeOptions={[5, 10, 25]}
              rows={contracts}
              columns={columns}
              autoHeight
              checkboxSelection
              loading={contractsLoading || props.vendorContractsLoading}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              filterModel={{
                items: [
                  {
                    id: 1,
                    field: "name",
                    operator: "contains",
                    value: searchText,
                  },
                ],
              }}
              slots={{
                toolbar: () => (
                  <EnhancedDataGridToolbar
                    view="table"
                    // setView={setView}
                    rowSelectionModel={rowSelectionModel}
                    handleDeleteClick={handleToolbarDeleteClick}
                    title={props.managed ? t("Managed Contracts") : title}
                    isLoading={
                      contractsLoading || props.vendorContractsLoading
                        ? true
                        : false
                    }
                    deleting={deleting}
                    customActions={[]}
                  />
                ),
                noRowsOverlay: () => (
                  <CustomNoRowsOverlay label={t("No contracts")} />
                ),
              }} // GridToolbar    GridToolbarQuickFilter
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
                pagination: {
                  showFirstButton: true,
                  showLastButton: true,
                },

                // pagination: {
                //   labelRowsPerPage: t("Rows per page"),
                //   labelDisplayedRows(paginationInfo) {
                //     return `${paginationInfo.from}-${paginationInfo.to} ${t("of")} ${paginationInfo.count}`;
                //   },
                // },
              }}
              sx={{
                "& .MuiDataGrid-cell": {
                  alignContent: "center",
                },
                "& .MuiDataGrid-columnHeader": {
                  background: (theme) => theme.palette.background.paper,
                },
              }}
              density="comfortable"
              onRowDoubleClick={handleRowDoubleClick}
            />
          )}
      </Box>
    </Paper>
  );
}

export default DataGridContracts;
