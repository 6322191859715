import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Card as MuiCard,
  Breadcrumbs as MuiBreadcrumbs,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Typography,
  Container,
  Popover,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { IDocument } from "../../types/document";

import useAuth from "../../hooks/useAuth";
import useBotChat from "../../hooks/useBotChat";
import OwlIcon from "../../icons/OwlIcon";
import {
  ChatType,
  IChatBotHistory,
  NewChatBoxMessage,
  TabType,
} from "../../types/chatBot";

const SearchIconWrapper = styled.div`
  height: 100%;
  // pointer-events: none;
  display: flex;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.header.color};

  svg {
    width: 22px;
    height: 22px;
  }
`;

const BorderlessTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
});

interface IAskMeAnythingProps {
  showLabel?: boolean;
  placeholder?: string;
}
function AskMeAnything({ showLabel, placeholder }: IAskMeAnythingProps) {
  const { t } = useTranslation();
  const { contractId, vendorId } = useParams();

  const { open: showChat, setOpen: setShowChat, addedMessage } = useBotChat();

  const { user } = useAuth();
  const { setAddedMessage } = useBotChat();
  const navigate = useNavigate();
  const [question, setQuestion] = useState<string>("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // const [showChat, setShowChat] = useState(false);
  const id = showChat ? "simple-popover" : undefined;
  const open = Boolean(anchorEl);
  const [tab, setTab] = useState<TabType>(TabType.home);
  const [chatBox, setChatBox] = useState<IChatBotHistory>({
    id: null,
    content: [],
    contractId,
    vendorId,
  });
  const [newMessage, setNewMessage] = useState<NewChatBoxMessage | null>(null);

  // create a reference to the input field
  const inputField = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (addedMessage) {
      setChatBox({
        id: null,
        content: [],
        contractId,
        vendorId,
      });
      setTab(TabType.chatBox);
      setNewMessage({
        type: ChatType.GENERAL,
        message: addedMessage,
      });
    }
  }, [addedMessage]);

  const handleEnterPress = (event: any) => {
    if (event.key === "Enter" && !!event.target.value) {
      handleClick(event);
      toggleChat();

      // console.log("Enter key pressed");
      // postQuestion({ question: event.target.value });
      setAddedMessage(event.target.value);
      event.target.value = "";
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(inputField.current); // event.currentTarget
  };

  const toggleChat = () => {
    if (open) {
      setAnchorEl(null);
      setShowChat(!showChat);
    } else {
      setAnchorEl(inputField.current);
      setShowChat(!showChat);
    }
  };

  const forceChat = (e: any) => {
    e.stopPropagation();
    // Clear the control position
    sessionStorage.clear();
    setAnchorEl(null);
    setShowChat(false);
  };

  return (
    <Grid container sx={{ position: "relative" }}>
      {showLabel && (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("botName")}
        </Typography>
      )}
      <BorderlessTextField
        sx={{
          background: (props) => props.palette.background.paper,
          borderRadius: "4px",
        }}
        aria-describedby={id}
        id="ask-me-anything-inputfield"
        ref={inputField}
        // variant="standard"
        fullWidth
        focused
        autoFocus
        disabled
        // placeholder={placeholder ? t(placeholder)! : t("Ask me something")!}
        placeholder={t("Coming soon")!}
        onKeyDown={handleEnterPress}
        slotProps={{
          input: {
            startAdornment: (
              <SearchIconWrapper>
                {/* <SearchIcon /> */}
                {/* <OwlAvatarIcon /> */}
                <IconButton
                  onClick={toggleChat}
                  onDoubleClick={forceChat}
                  disabled
                >
                  <OwlIcon />
                </IconButton>
              </SearchIconWrapper>
            ),
          },
        }}
      />
    </Grid>
  );
}

export default AskMeAnything;
