import React, { useEffect, useState } from "react";

import { Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AvatarGroup,
  createFilterOptions,
  Box,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import { DialogMode } from "../../types/dialogmode";
import useAuth from "../../hooks/useAuth";
import { IPrompt } from "../../types/prompt";
import { IGraphUser } from "../../types/user";
import { useTranslation } from "react-i18next";
import { HumanDataVerification } from "../../types/humanDataVerification";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface IHumanVerificationFormProps {
  mode: DialogMode;
  addVerification: (verification: HumanDataVerification) => Promise<void>;
  updateVerification: (verification: HumanDataVerification) => Promise<void>;
  verification?: HumanDataVerification;
  initialValues?: Partial<HumanDataVerification>;
}

function HumanVerificationForm({ ...props }: IHumanVerificationFormProps) {
  const { t } = useTranslation();
  // create a ref
  // const formikRef = useRef<FormikProps<any>>(null);

  const validationSchema = Yup.object().shape({
    comments: Yup.string().optional(),
  });

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      console.log("submitting form");
      if (props.mode === "add") {
        await props.addVerification(values);

        //   resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === "edit") {
        await props.updateVerification(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else {
        setStatus({ sent: false });
        setErrors({ submit: "Mode undefined" });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.comments });
      setSubmitting(false);
    }
  };

  const blankVerification: Partial<HumanDataVerification> = {
    comments: "",
  };

  const initialValues: Partial<HumanDataVerification> = props.verification
    ? props.verification
    : props.initialValues
    ? props.initialValues
    : blankVerification;

  const fieldVariant = "outlined"; // "outlined";
  const errorFieldVariant = "filled";

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Grid container mb={6}>
          {errors && !isValid && status?.sent && (
            <Grid>
              <Alert severity="error" my={3}>
                {t("An error occurred!")}
                {errors && Object.keys(errors).length > 0 && (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                )}
              </Alert>
            </Grid>
          )}

          <Grid width="100%">
            <Form id="verification-form" onSubmit={handleSubmit}>
              <Grid container gap={3} direction="column">
                <Grid>
                  <TextField
                    name="comments"
                    label={`${t("Comments")} ${
                      values.isCorrect
                        ? `(${t("Optional")?.toLowerCase()})`
                        : ``
                    }`}
                    value={values.comments}
                    error={Boolean(touched.comments && errors.comments)}
                    fullWidth
                    autoFocus
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onPaste={(e) => {
                      e.preventDefault();
                      let text: string = e.clipboardData.getData("text/plain");

                      // trim start and end
                      text = text.trim();
                      text = text.replace(/^\s+|\s+$/g, "");

                      setFieldValue("comments", text);
                    }}
                    variant={
                      touched.comments && errors.comments
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={true}
                    rows={8}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default HumanVerificationForm;
