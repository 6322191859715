import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  Button,
  Chip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AttachFile,
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  Gavel,
  HowToReg,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import {
  useAddDocumentContentMutation,
  useAddDocumentMutation,
  useAddHumanDataVerificationMutation,
  useDeleteDocumentMutation,
  useGetAttachmentsQuery,
  useGetDocumentsQuery,
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import { useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { format, isDate, set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { enGB, nl } from "date-fns/locale";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import AISuggestion from "./AISuggestion";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import useAuth from "../../../../hooks/useAuth";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { DocumentChip } from "./AIContractDetailSidebar";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

export const ContractDatesCard = ({
  startDate,
  endDate,
  signatureDate,
  explanation,
  viewMode,
}: {
  startDate?: string;
  endDate?: string;
  signatureDate?: string;
  explanation?: string;
  viewMode?: "compact" | "full";
}) => {
  const { t, i18n } = useTranslation();

  const isValidDate = (d?: string) => {
    if (!d || d === "null") return false;
    // check if the string is a valid date format
    return !isNaN(Date.parse(d));
  };

  return (
    <Grid container gap={2}>
      {startDate && isValidDate(startDate) && viewMode !== "compact" && (
        <Grid size={12}>
          <Typography variant="caption">{t("Start date")}</Typography>

          <Typography variant="body1">
            {format(new Date(startDate), "PP", {
              locale: i18n.language === "nl" ? nl : enGB,
            })}
          </Typography>
        </Grid>
      )}

      {endDate && isValidDate(endDate) && (
        <Grid size={12}>
          {" "}
          {viewMode !== "compact" && (
            <Typography variant="caption">{t("End date")}</Typography>
          )}
          <Typography variant="body1">
            {format(new Date(endDate), "PP", {
              locale: i18n.language === "nl" ? nl : enGB,
            })}
          </Typography>
        </Grid>
      )}

      {signatureDate &&
        isValidDate(signatureDate) &&
        viewMode !== "compact" && (
          <Grid size={12}>
            <Typography variant="caption">{t("Signature date")}</Typography>
            <Typography variant="body1">
              {format(new Date(signatureDate), "PP", {
                locale: i18n.language === "nl" ? nl : enGB,
              })}
            </Typography>
          </Grid>
        )}

      {explanation && viewMode !== "compact" && (
        <Grid size={12}>
          <Typography variant="body1">{explanation}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

interface IContractDatesProps {
  contract: IContract;
  documents: IDocument[];
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  hideTitle?: boolean;
}
function ContractDates({ ...props }: IContractDatesProps) {
  const { contract, mode, aiOutputs, documents } = props;
  const { t, i18n } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const isValidDate = (d?: string) => {
    if (!d || d === "null") return false;
    // check if the string is a valid date format
    return !isNaN(Date.parse(d));
  };

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("End date")}
        </Typography>
      )}

      <ContractDatesCard
        startDate={contract.dates?.startDate || contract.startDate}
        endDate={contract.dates?.endDate || contract.endDate}
        signatureDate={contract.dates?.signatureDate}
        explanation={contract.dates?.explanation}
      />

      {aiOutputs?.length > 0 && (
        <Box my={5}>
          <SimpleTreeView defaultExpandedItems={[`ai-output-items`]}>
            <TreeItem2
              itemId={`ai-output-items`}
              label={t("AI_suggestion", {
                count: aiOutputs?.length,
              })}
              slotProps={{
                content: {
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                  },
                },
              }}
            >
              {aiOutputs?.map((aiOutput) => {
                // if (aiOutput.id === latestAiOutput?.id) {
                //   // skip the latest ai output as it is already shown
                //   return null;
                // }

                const currentResult = aiOutput.metadata?.structuralData?.dates;

                if (!currentResult) {
                  return null;
                }

                const verificationRecord =
                  verifications?.find(
                    (v) => v.field === "endDate" && v.aiOutputId === aiOutput.id
                  ) ||
                  verifications?.find(
                    (v) => v.field === "dates" && v.aiOutputId === aiOutput.id
                  );

                const document = documents.find(
                  (d) => d.id === aiOutput.documentId
                );

                return (
                  <TreeItem2
                    itemId={`${aiOutput.id}-prompts`}
                    label={
                      <Grid container justifyContent="space-between">
                        <Typography>
                          {aiOutput.processedAt
                            ? t("date.dateTime", {
                                date: new Date(aiOutput.processedAt),
                              })
                            : "No processing date"}
                        </Typography>
                        {/* {document?.type === "attachment" && (
                          <AttachFile fontSize="small" />
                        )} */}

                        {/* {documents?.length > 1 && (
                          <DocumentChip document={document} />
                        )} */}
                      </Grid>
                    }
                    style={{
                      marginBottom: 5,
                      gap: 5,
                    }}
                    slotProps={{
                      content: {
                        style: {
                          paddingTop: 10,
                          paddingBottom: 10,
                        },
                      },
                    }}
                  >
                    <Box my={2} display="flex" flexDirection="column" gap={2}>
                      {document && documents?.length > 1 && (
                        <div>
                          <Chip
                            label={
                              document.type === "attachment"
                                ? document.name
                                : t("Main document")
                            }
                            color="primary"
                            size="small"
                            // icon={<AttachFile />}
                            variant="outlined"
                          />
                        </div>
                      )}

                      {verificationRecord && (
                        <VerifiedAISuggestion
                          verificationRecord={verificationRecord}
                        />
                      )}

                      <AISuggestion
                        contract={contract}
                        mode={mode}
                        isAiSuggestion={!verificationRecord}
                        isVerified={verificationRecord?.isCorrect !== undefined}
                        field="dates"
                        value={aiOutput?.metadata?.structuralData?.dates}
                        aiInputId={aiOutput.aiInputId}
                        aiOutputId={aiOutput.id}
                        content={
                          <Box mb={3}>
                            <ContractDatesCard
                              {...aiOutput.metadata.structuralData.dates}
                            />
                          </Box>
                        }
                        processedAt={aiOutput?.processedAt}
                      />
                    </Box>
                  </TreeItem2>
                );
              })}
            </TreeItem2>
          </SimpleTreeView>
        </Box>
      )}
    </Box>
  );
}

export default ContractDates;
