import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Icon,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Stack,
  CardActions,
  Button,
  Chip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AutoAwesome,
  CheckCircleOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  InsertDriveFileOutlined,
  MoreHoriz as MoreHorizIcon,
  Person,
  Store,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";
import { DialogMode } from "../../../../types/dialogmode";
import { IContract } from "../../../../types/contract";
import { IDocument } from "../../../../types/document";
import CustomNoRowsOverlay from "../../../../components/datagrids/CustomNoRowsOverlay";
import { spacing } from "@mui/system";
import FileAsListItem from "../../../../components/lists/listitems/FileAsListItem";
import { useGetHumanDataVerificationsQuery } from "../../../../redux/slices/indexApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import FilesToUploadList from "../../../../components/lists/FilesToUploadList";
import { IVendor } from "../../../../types/vendor";
import { set } from "date-fns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import VendorAsListItem from "../../../../components/lists/listitems/VendorAsListItem";
import VendorAvatar from "../../../../components/avatars/VendorAvatar";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { C } from "@fullcalendar/core/internal-common";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import useAuth from "../../../../hooks/useAuth";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import AISuggestion from "./AISuggestion";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";

const UploadButton = styled(MuiPaper)`
  height: 144px;
  border: 1px dashed ${(props) => props.theme.palette.divider};
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px dashed ${(props) => props.theme.palette.primary.main};
  }
`;

export const PartyDetails = ({
  name,
  address,
  contactPersons,
}: {
  name: string;
  address: string;
  contactPersons: { name: string; role: string }[];
}) => {
  return (
    <Typography variant="body2">
      <Box>
        <Box>{name}</Box>
        <Box>{address}</Box>

        <Box mb={3}>
          <Grid container spacing={3} pt={2}>
            {contactPersons?.map((person: any, i: number) => {
              return (
                <Grid key={`contactPerson-${i}-${person.email}`}>
                  <ContactPersonCard {...person} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Typography>
  );
};

const ContactPersonCard = ({
  name,
  email,
  phone,
  role,
}: {
  name: string;
  email: string;
  phone: string;
  role: string;
}) => {
  return (
    <Alert
      severity="info"
      icon={<Person />}
      variant="outlined"
      sx={{ minWidth: 200, border: "none" }}
    >
      <Typography variant="body1">{name}</Typography>
      <Typography variant="body1">{email}</Typography>
      <Typography variant="body1">{phone}</Typography>
      <Typography variant="body1">{role}</Typography>
    </Alert>
  );
};

interface IContractPartiesProps {
  contract: IContract;
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  hideTitle?: boolean;
  setBadgeCount?: (count: number) => void;
  documents: IDocument[];
}
function ContractParties({ ...props }: IContractPartiesProps) {
  const { contract, mode, aiOutputs, documents } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Parties")}
        </Typography>
      )}

      <Stack gap={2}>
        {contract?.seller && (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="body2" mb={2}>
                {t("Vendor")}
              </Typography>

              {contract?.seller && <PartyDetails {...contract.seller} />}

              {contract?.explanations?.seller && (
                <Alert
                  sx={{ mt: 2 }}
                  severity="info"
                  variant="outlined"
                  icon={<OwlAvatarIcon />}
                >
                  <Typography variant="body1">
                    {contract?.explanations.seller}
                  </Typography>
                </Alert>
              )}

              {contract?.vendorId && (
                <Button
                  sx={{ mt: 3, mb: 2 }}
                  size="small"
                  color="primary"
                  variant="outlined"
                  startIcon={<Store />}
                  onClick={() => navigate(`/vendor/${contract.vendorId}`)}
                >
                  {t("Vendor page")}
                </Button>
              )}
            </CardContent>
          </Card>
        )}

        {contract?.buyer && (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="body2" mb={2}>
                {t("Customer")}
              </Typography>

              {contract?.buyer && <PartyDetails {...contract.buyer} />}

              {contract?.explanations?.buyer && (
                <Alert
                  sx={{ mt: 2 }}
                  severity="info"
                  variant="outlined"
                  icon={<OwlAvatarIcon />}
                >
                  <Typography variant="body1">
                    {contract?.explanations.buyer}
                  </Typography>
                </Alert>
              )}
            </CardContent>
          </Card>
        )}

        {contract?.vendorId && (
          <Button
            sx={{ mt: 3, mb: 2 }}
            size="small"
            color="primary"
            variant="outlined"
            startIcon={<Store />}
            onClick={() => navigate(`/vendor/${contract.vendorId}`)}
          >
            {t("Vendor page")}
          </Button>
        )}
      </Stack>

      {aiOutputs?.length > 0 && (
        <Box my={5}>
          <SimpleTreeView defaultExpandedItems={[`ai-output-items`]}>
            <TreeItem2
              itemId={`ai-output-items`}
              label={t("AI_suggestion", {
                count: aiOutputs?.length,
              })}
              slotProps={{
                content: {
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                  },
                },
              }}
            >
              {aiOutputs?.map((aiOutput) => {
                // if (aiOutput.id === latestAiOutput?.id) {
                //   // skip the latest ai output as it is already shown
                //   return null;
                // }

                const currentResult = aiOutput.metadata?.structuralData?.buyer;

                if (!currentResult) {
                  return null;
                }

                const verificationRecord = verifications?.find(
                  (v) =>
                    (v.field === "buyer" ||
                      v.field === "seller" ||
                      v.field === "contractParties") &&
                    v.aiOutputId === aiOutput.id
                );

                const document = documents.find(
                  (d) => d.id === aiOutput.documentId
                );

                return (
                  <TreeItem2
                    itemId={`${aiOutput.id}-prompts`}
                    label={
                      <Typography>
                        {aiOutput.processedAt
                          ? t("date.dateTime", {
                              date: new Date(aiOutput.processedAt),
                            })
                          : "No processing date"}
                      </Typography>
                    }
                    style={{
                      marginBottom: 5,
                      gap: 5,
                    }}
                    slotProps={{
                      content: {
                        style: {
                          paddingTop: 10,
                          paddingBottom: 10,
                        },
                      },
                    }}
                  >
                    <Box my={2} display="flex" flexDirection="column" gap={2}>
                      {document && documents?.length > 1 && (
                        <div>
                          <Chip
                            label={
                              document.type === "attachment"
                                ? document.name
                                : t("Main document")
                            }
                            color="primary"
                            size="small"
                            // icon={<AttachFile />}
                            variant="outlined"
                          />
                        </div>
                      )}

                      {verificationRecord && (
                        <VerifiedAISuggestion
                          verificationRecord={verificationRecord}
                        />
                      )}

                      <AISuggestion
                        contract={contract}
                        mode={mode}
                        isAiSuggestion={!verificationRecord}
                        collapsible={false}
                        isVerified={verificationRecord?.isCorrect !== undefined}
                        field="buyer"
                        value={aiOutput?.metadata?.structuralData?.buyer}
                        field2="seller"
                        value2={aiOutput?.metadata?.structuralData?.seller}
                        aiInputId={aiOutput.aiInputId}
                        aiOutputId={aiOutput.id}
                        content={
                          <Stack gap={2}>
                            {aiOutput?.metadata?.structuralData?.seller && (
                              <Card variant="outlined">
                                <CardContent>
                                  <Typography variant="body2" mb={2}>
                                    {t("Vendor")}
                                  </Typography>

                                  <PartyDetails
                                    {...aiOutput?.metadata?.structuralData
                                      ?.seller}
                                  />

                                  {!aiOutput?.metadata?.structuralData?.seller
                                    ?.explanation && (
                                    <Typography variant="body1">
                                      {
                                        aiOutput?.metadata?.structuralData
                                          ?.seller?.explanation
                                      }
                                    </Typography>
                                  )}
                                </CardContent>
                              </Card>
                            )}

                            {aiOutput?.metadata?.structuralData?.buyer && (
                              <Card variant="outlined">
                                <CardContent>
                                  <Typography variant="body2" mb={2}>
                                    {t("Customer")}
                                  </Typography>

                                  <PartyDetails
                                    {...aiOutput?.metadata?.structuralData
                                      ?.buyer}
                                  />

                                  {aiOutput?.metadata?.structuralData?.buyer
                                    ?.explanation && (
                                    <Typography variant="body1">
                                      {
                                        aiOutput?.metadata?.structuralData
                                          ?.buyer?.explanation
                                      }
                                    </Typography>
                                  )}
                                </CardContent>
                              </Card>
                            )}
                          </Stack>
                        }
                        processedAt={aiOutput?.processedAt}
                      />
                    </Box>
                  </TreeItem2>
                );
              })}
            </TreeItem2>
          </SimpleTreeView>
        </Box>
      )}
    </Box>
  );
}

export default ContractParties;
