import styled from "@emotion/styled";
import { borderBottom, spacing } from "@mui/system";
import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  useTheme,
  Typography,
  useMediaQuery,
  Divider as MuiDivider,
  Tabs,
  Tab,
  AppBar,
  Card,
  Paper,
  Stack,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  BadgeProps,
  AccordionProps,
  List,
  CardHeader,
  CardContent,
  Chip,
  Alert,
  AlertTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SidebarAnalyzeDocument from "./SidebarRiskAnalysis";
import AIContractSigneeManagement from "./AIContractSigneeManagement";
import ContractDocumentInformation from "./ContractDocumentInformation";
import { IDocument } from "../../../../types/document";
import { DialogMode } from "../../../../types/dialogmode";
import { ISignature, ISignee } from "../../../../types/signature";
import { useGetDriveItemQuery } from "../../../../redux/slices/graphApiSlice";
import {
  AIReadingStatus,
  ContractStatus,
  IContract,
} from "../../../../types/contract";

import ContractAttachments from "./ContractAttachments";
import ContractNotes from "./ContractNotes";
import useAuth from "../../../../hooks/useAuth";
import SidebarReadDocument from "./SidebarReadDocument";
import SidebarRiskAnalysis from "./SidebarRiskAnalysis";
import AskMeAnything from "../../../components/AskMeAnything";
import SidebarRoles from "./SidebarRoles";
import OwlIcon from "../../../../icons/OwlIcon";
import {
  Assistant,
  AttachFile,
  Attachment,
  AutoAwesome,
  AutoGraph,
  Block,
  Cancel,
  CancelPresentation,
  Chat,
  Check,
  Checklist,
  Class,
  Close,
  CollectionsBookmark,
  Euro,
  Event,
  EventBusy,
  FolderCopy,
  Folder as FolderIcon,
  Gavel,
  Group,
  Groups,
  HistoryEdu,
  Label,
  LocalOffer,
  ManageAccounts,
  MenuBook,
  Money,
  PanTool,
  Person,
  Public,
  QuestionMark,
  Settings,
  SmartButton,
  Store,
  Style,
  Subject,
  Summarize,
  TaskAlt,
  TipsAndUpdates,
  WhereToVote,
} from "@mui/icons-material";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import NoteDialog from "../../../../components/dialogs/NoteDialog";
import ContractSummary from "./ContractSummary";
import ContractParties, { PartyDetails } from "./ContractParties";
import ContractContactPersons from "./ContractContactPersons";
import ContractFinancials, { FinancialsCard } from "./ContractFinancials";
import { DollarSign } from "react-feather";
import ContractJurisdiction from "./ContractJurisdiction";
import ContractNoticePeriod, { NoticePeriodCard } from "./ContractNoticePeriod";
import ContractDates, { ContractDatesCard } from "./ContractDates";
import ContractCategory from "./ContractCategory";
import ContractType from "./ContractType";
import ContractSubject from "./ContractSubject";
import {
  useAddHumanDataVerificationMutation,
  useGetAIOutputQuery,
  useGetAttachmentsQuery,
  useGetHumanDataVerificationsQuery,
  useGetPromptsQuery,
  useLazyGetAIOutputQuery,
} from "../../../../redux/slices/indexApiSlice";
import ContractPromptResponse from "./ContractPromptResponse";
import { IPrompt } from "../../../../types/prompt";
import ContractFolders from "./ContractFolders";
import { use } from "i18next";
import {
  ContractExtractionResult,
  Paragraph,
  PromptExtractionResult,
} from "../../../../types/aiOutput";
import AISuggestion from "./AISuggestion";
import ContractPromptAccordion from "./ContractPromptAccordion";
import { format } from "date-fns";
import PromptHeader from "../../dashboards/Analytics/PromptHeader";
import { HumanDataVerification } from "../../../../types/humanDataVerification";

const Divider = styled(MuiDivider)(spacing);

// create an enum for the tabs
export enum SideBarTabIndex {
  BASIC_DETAILS,
  FIELDS_FOUND,
  FIELDS_NOT_FOUND,
  FIELDS_EXCLUDED,
  CONTRACT_MANAGEMENT,
  AI_ASSISTANT,
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 11,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
type AIContractDetailSidebarProps = {
  mode: DialogMode;
  signer: ISignee | undefined;
  signees: ISignee[];
  contract: IContract;
  documents: IDocument[];
  document: IDocument;
  aiOutputs: ContractExtractionResult[];
  analysisStatus: AIReadingStatus;
  onAnalysis: () => void;
  setSignees: React.Dispatch<React.SetStateAction<Array<ISignee>>>;
  setSignatures: React.Dispatch<React.SetStateAction<Array<ISignature>>>;
  signatures: ISignature[];
  resetSignatures?: React.Dispatch<React.SetStateAction<boolean>>;
  refetchContract?: () => void;
  setSearchForText?: (text: string) => void;
  promptIdToExpand?: string;
  handlePromptClick: (prompt?: IPrompt) => void;
  setAiOutput: React.Dispatch<
    React.SetStateAction<ContractExtractionResult | undefined>
  >;
  setSelectedParagraph: React.Dispatch<
    React.SetStateAction<Paragraph | undefined>
  >;
  sideBarTab: SideBarTabIndex;
  setSideBarTab: React.Dispatch<React.SetStateAction<SideBarTabIndex>>;
  switchToDocument: (documentId: string) => void;
};

export const DocumentChip = ({ ...props }: { document?: IDocument }) => {
  const { document } = props;

  return (
    <Chip
      label={document?.name}
      icon={document?.type === "attachment" ? <AttachFile /> : <Gavel />}
      variant="outlined"
      color="primary"
    />
  );
};

const PromptsSection = ({
  contract,
  prompts,
  mode,
  aiOutputs,
  selectParagraph,
  expanded,
  infoFound,
  verifications,
  documents,
}: {
  contract: IContract;
  prompts: IPrompt[];
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  selectParagraph: (
    paragraph?: Paragraph,
    prompt?: IPrompt,
    aiOutput?: ContractExtractionResult
  ) => void;
  expanded?: string;
  infoFound: boolean;
  verifications?: HumanDataVerification[];
  documents: IDocument[];
}) => {
  const { t } = useTranslation();

  const [promptsCorrectCount, setPromptsCorrectCount] = useState<number>(0);
  const [promptsIncorrectCount, setPromptsIncorrectCount] = useState<number>(0);
  const [promptsUnknownCount, setPromptsUnknownCount] = useState<number>(0);

  useEffect(() => {
    if (verifications && verifications?.length > 0) {
      // check if the latest aiOutput has a result for this prompt

      // for each prompt, check if there is a verification
      const verifiedPrompts = prompts.map((prompt) => {
        const promptResults = aiOutputs.filter((a) =>
          a.prompts?.some((p) => p.promptID === prompt.id)
        );

        const latestPromptResult = promptResults[0];

        const verification = verifications.find(
          (v) =>
            latestPromptResult &&
            v.aiOutputId === latestPromptResult.id &&
            v.field === prompt.id
        );

        return {
          ...prompt,
          isCorrect: verification?.isCorrect,
        };
      });

      // calculate the score
      const total = prompts.length;
      const correct = verifiedPrompts.filter((p) => p.isCorrect).length;
      const inCorrect = verifiedPrompts.filter(
        (p) => p.isCorrect === false
      ).length;
      const unknown = verifiedPrompts.filter((p) => p.isCorrect === undefined);

      setPromptsCorrectCount(correct);
      setPromptsIncorrectCount(inCorrect);
      setPromptsUnknownCount(unknown.length);
    }
  }, [verifications]);

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {/* <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Fields found")}
            </Typography> */}

      {prompts?.length > 0 && (
        <PromptHeader
          amount={promptsCorrectCount}
          totalAmount={prompts.length}
          title={t("Correct")!}
          description={
            <div>
              {t("Incorrect")}: {promptsIncorrectCount}
              <br />
              {t("Unknown")}: {promptsUnknownCount}
              <br />
            </div>
          }
        />
      )}

      {prompts.map((prompt) => {
        return (
          <ContractPromptAccordion
            key={`contractPromptAccordion-${prompt.id}`}
            prompt={prompt}
            contract={contract}
            mode={mode}
            aiOutputs={aiOutputs}
            selectParagraph={selectParagraph}
            isExpanded={expanded === prompt.id}
            infoFound={infoFound}
            documents={documents}
            // attachments={attachments}
          />
        );
      })}
    </Box>
  );
};

const AIContractDetailSidebar: React.FC<AIContractDetailSidebarProps> = ({
  mode,
  signer,
  signees,
  contract,
  documents,
  document,
  aiOutputs,
  analysisStatus,
  onAnalysis,
  setSignees,
  setSignatures,
  signatures,
  resetSignatures,
  refetchContract,
  setSearchForText,
  handlePromptClick,
  promptIdToExpand,
  setAiOutput,
  setSelectedParagraph,
  sideBarTab,
  setSideBarTab,
  switchToDocument,
}) => {
  const { t } = useTranslation();
  const { id, status } = useParams();
  const theme = useTheme();

  const [badges, setBadges] = useState<{ [key: string]: number }>({});

  const [addVerification] = useAddHumanDataVerificationMutation();

  const { data: prompts = [], isSuccess: promptsLoaded } = useGetPromptsQuery();
  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  // const [aiOutputs, setAiOutputs] = useState<ContractExtractionResult[]>([]);

  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [expanded, setExpanded] = useState<string>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSideBarTab(newValue);
  };

  const setBadge = (tabIndex: string, num: number) => {
    setBadges((prev) => ({ ...prev, [tabIndex]: num }));
  };

  const selectParagraph = (
    paragraph?: Paragraph,
    prompt?: IPrompt,
    aiOutput?: ContractExtractionResult
  ) => {
    if (!prompt) {
      setExpanded(undefined);
      handlePromptClick(undefined);
      return;
    }

    // check if we need to switch to another document
    if (aiOutput?.documentId && document.id !== aiOutput?.documentId) {
      switchToDocument(aiOutput.documentId);

      setTimeout(() => {
        setExpanded(prompt.id);
        setSelectedParagraph(paragraph);
        setAiOutput(aiOutput);
        handlePromptClick(prompt);
      }, 1300);
    } else {
      setExpanded(prompt.id);
      setSelectedParagraph(paragraph);
      setAiOutput(aiOutput);
      handlePromptClick(prompt);
    }
  };

  useEffect(() => {
    if (promptIdToExpand) {
      // findHighlight(null, true, promptFind);
      setExpanded(promptIdToExpand);
    }
  }, [promptIdToExpand]);

  // useEffect(() => {
  //   const sorted = aiOutputsMainContract
  //     .concat(aiOutputsAttachments)
  //     .sort((a, b) => {
  //       // sort the aiOutputs by date
  //       return (
  //         new Date(b.processedAt).getTime() - new Date(a.processedAt).getTime()
  //       );
  //     });

  //   setAiOutputs(sorted);
  // }, [aiOutputsMainContract, aiOutputsAttachments]);

  // useEffect(() => {
  //   if (attachments && attachments?.length > 0) {
  //     attachments.forEach((attachment) => {
  //       getAttachmentAIOutput(attachment)
  //         .unwrap()
  //         .then((result) => {
  //           if (result) {
  //             // add the result to the aiOutputs
  //             const newAIOutputs = aiOutputsAttachments.concat(result);
  //             setAIOutputAttachments(newAIOutputs);
  //           }
  //         });
  //     });
  //   } else {
  //     setAIOutputAttachments([]);
  //   }
  // }, [attachments]);

  const promptWasFound = (p: IPrompt) => {
    if (aiOutputs?.length === 0) return false;

    const latestForPrompt = aiOutputs.filter((a) =>
      // !attachments?.some((att) => att.id === a.documentId) &&
      a.prompts?.some((b) => b.promptID === p.id)
    );

    if (latestForPrompt.length === 0) return false;

    return latestForPrompt[0].prompts?.some(
      (pr) => pr.extractionResult?.infoFound
    );
  };

  const promptIsExcluded = (p: IPrompt): boolean => {
    return p.id && contract?.exludedPromptIds?.includes(p.id) ? true : false;
  };

  const getBadgeContent = (isVerified: boolean, hasAiSuggestion: boolean) => {
    return isVerified ? (
      <Check sx={{ maxWidth: 8, maxHeight: 16 }} />
    ) : hasAiSuggestion ? (
      <QuestionMark sx={{ maxWidth: 8, maxHeight: 16 }} />
    ) : (
      <Close sx={{ maxWidth: 8, maxHeight: 16 }} />
    );
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        width: "100%",
        mb: 6,
        py: 3,
        px: 3,
        backgroundColor: "background.default",
        minHeight: 500,
        overflow: "auto",
        maxHeight: "calc(100vh - 380px)",
      }}
    >
      <Paper sx={{ mb: 4 }}>
        <Tabs
          onChange={handleChange}
          value={sideBarTab}
          orientation="horizontal"
          // centered
          variant="scrollable"
          // sx={{
          //   "& .MuiTabs-flexContainer": {
          //     flexWrap: "wrap",
          //   },
          //   "& .MuiTabs-indicator": {
          //     borderBottom: "none",
          //   },
          // }}
        >
          <Tab label={t("Basic details")} icon={<ManageAccounts />} />
          <Tab label={t("Fields found")} icon={<Checklist />} />
          <Tab label={t("Fields not found")} icon={<Cancel />} />
          <Tab label={t("Fields excluded")} icon={<Block />} />
          <Tab label={t("Contract management")} icon={<Settings />} />
          <Tab label={t("AI assistant")} icon={<OwlAvatarIcon />} />
        </Tabs>
      </Paper>

      <Grid container direction="column" gap={3}>
        {sideBarTab === SideBarTabIndex.AI_ASSISTANT && (
          <AskMeAnything
            showLabel
            placeholder={"Ask a question about this contract"}
          />
        )}

        {sideBarTab === SideBarTabIndex.CONTRACT_MANAGEMENT && (
          <Stack>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Contract management")}
            </Typography>

            <Accordion disabled>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-airead"
                id="panel-airead-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <AutoAwesome />
                  </ListItemIcon>
                  <ListItemText primary={t("AI_Analysis")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <SidebarRiskAnalysis
                  contract={contract}
                  document={document}
                  isDownLg={isDownLg}
                  analyzing={analysisStatus === AIReadingStatus.ANALYZING}
                  onAnalysis={onAnalysis}
                  isAnalyzed={!!contract?.analyzeStatus}
                  hideTitle
                />
              </AccordionDetails>
              {/* <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions> */}
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-attachments"
                id="panel-attachments-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={badges.attachments}
                      color="info"
                      showZero
                    >
                      <AttachFile />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Attachments")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractAttachments
                  mode={DialogMode.Edit}
                  contract={contract}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("attachments", num)}
                  contractWasAnalysed={aiOutputs.length > 0}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion disabled>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-signees"
                id="panel-signees-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <HistoryEdu />
                  </ListItemIcon>
                  <ListItemText primary={t("Signees")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <AIContractSigneeManagement
                  mode={
                    status === ContractStatus.SIGNED ||
                    status === ContractStatus.ACTIVE ||
                    status === ContractStatus.ARCHIVED
                      ? DialogMode.ViewOnly
                      : DialogMode.Edit
                  }
                  signer={signer}
                  readOnly={false} // {mode === DialogMode.ViewOnly}
                  contract={contract}
                  signable={false} // signees?.find((s) => s.email === user?.email) !== undefined
                  signees={signees}
                  setSignees={setSignees}
                  setSignatures={setSignatures}
                  signatures={signatures}
                  resetSignatures={resetSignatures}
                  document={document}
                  hideTitle
                />
              </AccordionDetails>
              {/* <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions> */}
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-roles"
                id="panel-roles-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <Groups />
                  </ListItemIcon>
                  <ListItemText primary={t("Roles")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <SidebarRoles
                  contract={contract}
                  hideTitle
                  readonly={mode === DialogMode.ViewOnly}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-tasks"
                id="panel-tasks-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={badges.tasks}
                      color="info"
                      showZero
                    >
                      <TaskAlt />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Notes")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractNotes
                  contract={contract}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("tasks", num)}
                />
              </AccordionDetails>
              {/* <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions> */}
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-folders"
                id="panel-folders-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <FolderCopy />
                  </ListItemIcon>
                  <ListItemText primary={t("Folders")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractFolders contract={contract} hideTitle />
              </AccordionDetails>
            </Accordion>
          </Stack>
        )}

        {sideBarTab === SideBarTabIndex.BASIC_DETAILS && (
          <Stack>
            {/* {contract?.extraContext && (
            <Box>
              {contract.extraContext.map((context) => (
                <Box py={3}>
                  <Typography>{context.property}</Typography>
                  <Box>{context.details}</Box>
                  <Box>{context.locationInText}</Box>
                </Box>
              ))}
            </Box>
          )} */}

            {/* <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Basic details")}
            </Typography> */}

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-category"
                id="panel-category-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract.category || contract.procurement?.category
                          ? true
                          : false,
                        aiOutputs?.some(
                          (a) =>
                            a.metadata?.structuralData?.procurement?.category
                        )
                          ? true
                          : false
                      )}
                      color={
                        contract.category || contract.procurement?.category
                          ? "success"
                          : aiOutputs?.some(
                              (a) =>
                                a.metadata?.structuralData?.procurement
                                  ?.category
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Style />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Category")}
                    secondary={
                      contract?.category
                        ? t(contract?.category)
                        : contract.procurement?.category
                        ? t(contract.procurement?.category)
                        : ""
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractCategory
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            {(contract?.buyer?.contactPersons?.length > 0 ||
              contract?.seller?.contactPersons?.length > 0) && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-contactpersons"
                  id="panel-contactpersons-header"
                >
                  <ListItem disablePadding disableGutters>
                    <ListItemIcon>
                      <StyledBadge
                        badgeContent={getBadgeContent(
                          contract.buyer?.contactPersons?.length > 0 ||
                            contract.seller?.contactPersons?.length > 0,
                          aiOutputs?.some(
                            (a) =>
                              a.metadata?.structuralData?.seller
                                ?.contactPersons ||
                              a.metadata?.structuralData?.buyer?.contactPersons
                          )
                        )}
                        color={
                          contract.buyer?.contactPersons?.length > 0 ||
                          contract.seller?.contactPersons?.length > 0
                            ? "success"
                            : aiOutputs?.some(
                                (a) =>
                                  a.metadata?.structuralData?.seller
                                    ?.contactPersons ||
                                  a.metadata?.structuralData?.buyer
                                    ?.contactPersons
                              )
                            ? "info"
                            : "error"
                        }
                        showZero
                      >
                        <Person />
                      </StyledBadge>
                    </ListItemIcon>
                    <ListItemText
                      primary={t("Contact persons")}
                      secondary={[
                        ...contract.seller?.contactPersons,
                        ...contract.buyer?.contactPersons,
                      ]
                        .map((cp) => cp.name)
                        .join(", ")}
                    />
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails>
                  <ContractContactPersons
                    mode={mode}
                    contract={contract}
                    aiOutputs={aiOutputs}
                    hideTitle
                    documents={documents}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-enddate"
                id="panel-enddate-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.endDate || !!contract.dates?.endDate,
                        aiOutputs?.some(
                          (a) => a.metadata?.structuralData?.dates
                        )
                      )}
                      color={
                        contract.endDate || contract.dates?.endDate
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.dates
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Event />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("End date")}
                    secondary={
                      contract.dates?.endDate ? (
                        <ContractDatesCard
                          {...contract.dates}
                          viewMode="compact"
                        />
                      ) : (
                        <ContractDatesCard {...contract} viewMode="compact" />
                      )
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractDates
                  contract={contract}
                  documents={documents}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-financials"
                id="panel-financials-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract.budget?.amount || contract.budget?.totalValue
                          ? true
                          : false,
                        aiOutputs?.some(
                          (a) =>
                            a.metadata?.structuralData?.financials?.totalValue
                        )
                      )}
                      color={
                        contract?.budget?.amount || contract?.budget?.totalValue
                          ? "success"
                          : aiOutputs?.some(
                              (a) =>
                                a.metadata?.structuralData?.financials
                                  ?.totalValue
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      {contract?.budget?.currency === "EUR" ? (
                        <Euro />
                      ) : contract?.budget?.currency === "USD" ? (
                        <DollarSign />
                      ) : (
                        <Money />
                      )}
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      // <Badge
                      //   variant="dot"
                      //   color="warning"
                      //   badgeContent=" "
                      //   // overlap="circular"
                      //   anchorOrigin={{
                      //     vertical: "top",
                      //     horizontal: "left",
                      //   }}
                      // >
                      t("Financials")
                      // </Badge>
                    }
                    secondary={
                      contract?.budget && (
                        <FinancialsCard
                          {...contract.budget}
                          viewMode="compact"
                        />
                      )
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractFinancials
                  mode={mode}
                  contract={contract}
                  documents={documents}
                  aiOutputs={aiOutputs}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-jurisdiction"
                id="panel-jurisdiction-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.countryOfJurisdiction,
                        aiOutputs?.some(
                          (a) =>
                            a.metadata?.structuralData?.countryOfJurisdiction
                        )
                      )}
                      color={
                        contract.countryOfJurisdiction
                          ? "success"
                          : aiOutputs?.some(
                              (a) =>
                                a.metadata?.structuralData
                                  ?.countryOfJurisdiction
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Public />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Country of jurisdiction")}
                    secondary={contract?.countryOfJurisdiction}
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractJurisdiction
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-subject"
                id="panel-subject-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.subject,
                        aiOutputs?.some(
                          (a) => a.metadata?.structuralData?.subject
                        )
                      )}
                      color={
                        contract?.subject
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.subject
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Subject />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Subject")}
                    secondary={contract.subject}
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractSubject
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-noticeperiod"
                id="panel-noticeperiod-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract.noticePeriod?.unit &&
                          contract.noticePeriod?.value
                          ? true
                          : false,
                        aiOutputs?.some(
                          (a) => a.metadata?.structuralData?.noticePeriod
                        )
                          ? true
                          : false
                      )}
                      color={
                        contract.noticePeriod?.unit &&
                        contract.noticePeriod?.value
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.noticePeriod
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <PanTool />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Notice period")}
                    secondary={
                      <NoticePeriodCard
                        {...contract.noticePeriod}
                        viewMode="compact"
                      />
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractNoticePeriod
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-parties"
                id="panel-parties-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract?.buyer ||
                          contract?.seller ||
                          contract?.vendorName ||
                          contract?.vendorId
                          ? true
                          : false,
                        aiOutputs?.some(
                          (a) =>
                            a.metadata?.structuralData?.seller ||
                            a.metadata?.structuralData?.buyer
                        )
                          ? true
                          : false
                      )}
                      color={
                        contract?.buyer ||
                        contract?.seller ||
                        contract?.vendorName ||
                        contract?.vendorId
                          ? "success"
                          : aiOutputs?.some(
                              (a) =>
                                a.metadata?.structuralData?.seller ||
                                a.metadata?.structuralData?.buyer
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Store />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Parties")}
                    secondary={
                      <>
                        {contract.buyer?.name ? contract.buyer?.name : ""}
                        {contract.buyer?.name && contract.seller?.name
                          ? ", "
                          : ""}
                        {contract.seller?.name ? contract.seller?.name : ""}
                      </>
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractParties
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("parties", num)}
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-summary"
                id="panel-summary-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.summary,
                        aiOutputs?.some(
                          (a) => a.metadata?.structuralData?.summary
                        )
                      )}
                      color={
                        contract?.summary
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.summary
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <MenuBook />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Summary")}
                    secondary={
                      contract.summary &&
                      contract.summary?.substring(0, 100) + "..."
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractSummary
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("summary", num)}
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-type"
                id="panel-type-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.type,
                        aiOutputs?.some((a) => a.metadata?.structuralData?.type)
                      )}
                      color={
                        contract?.type
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.type
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Class />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Type")}
                    secondary={t(contract.type || "")}
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractType
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>
          </Stack>
        )}

        {sideBarTab === SideBarTabIndex.FIELDS_FOUND && (
          <PromptsSection
            contract={contract}
            prompts={prompts
              ?.filter(
                (p) =>
                  p.enabled &&
                  !p.hidden &&
                  promptWasFound(p) &&
                  !promptIsExcluded(p)
              )
              .sort((a, b) => {
                // sort on the translated title
                const aCategory = a.title ? t(a.title)?.toLowerCase() : "";
                const bCategory = b.title ? t(b.title)?.toLowerCase() : "";

                if (aCategory < bCategory) {
                  return -1;
                }
                if (aCategory > bCategory) {
                  return 1;
                }
                return 0;
              })}
            mode={mode}
            aiOutputs={aiOutputs}
            selectParagraph={selectParagraph}
            expanded={expanded}
            infoFound={true}
            verifications={verifications}
            documents={documents}
          />
        )}

        {sideBarTab === SideBarTabIndex.FIELDS_NOT_FOUND && (
          <PromptsSection
            contract={contract}
            prompts={prompts
              ?.filter(
                (p) =>
                  p.enabled &&
                  !p.hidden &&
                  !promptWasFound(p) &&
                  !promptIsExcluded(p)
              )
              .sort((a, b) => {
                // sort on the translated title
                const aCategory = a.title ? t(a.title)?.toLowerCase() : "";
                const bCategory = b.title ? t(b.title)?.toLowerCase() : "";

                if (aCategory < bCategory) {
                  return -1;
                }
                if (aCategory > bCategory) {
                  return 1;
                }
                return 0;
              })}
            mode={mode}
            aiOutputs={aiOutputs}
            selectParagraph={selectParagraph}
            expanded={expanded}
            infoFound={false}
            verifications={verifications}
            documents={documents}
          />
        )}

        {sideBarTab === SideBarTabIndex.FIELDS_EXCLUDED && (
          <PromptsSection
            contract={contract}
            prompts={prompts
              ?.filter((p) => promptIsExcluded(p))
              .sort((a, b) => {
                // sort on the translated title
                const aCategory = a.title ? t(a.title)?.toLowerCase() : "";
                const bCategory = b.title ? t(b.title)?.toLowerCase() : "";

                if (aCategory < bCategory) {
                  return -1;
                }
                if (aCategory > bCategory) {
                  return 1;
                }
                return 0;
              })}
            mode={mode}
            aiOutputs={aiOutputs}
            selectParagraph={selectParagraph}
            expanded={expanded}
            infoFound={false}
            verifications={verifications}
            documents={documents}
          />
        )}
      </Grid>
    </Paper>
  );
};

export default AIContractDetailSidebar;
