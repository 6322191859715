import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { blue, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip as MuiChip,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  StepButton,
  Typography,
  useMediaQuery,
  useTheme,
  Menu,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  Fade,
  Alert,
  AlertTitle,
  Chip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IContract } from "../../../../types/contract";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import {
  ContractExtractionResult,
  PromptExtractionResult,
} from "../../../../types/aiOutput";
import {
  useAddHumanDataVerificationMutation,
  useGetHumanDataVerificationsQuery,
  useUpdateContractMutation,
} from "../../../../redux/slices/indexApiSlice";
import AISuggestion from "./AISuggestion";
import { HowToReg } from "@mui/icons-material";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import useAuth from "../../../../hooks/useAuth";
import { DialogMode } from "../../../../types/dialogmode";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { IDocument } from "../../../../types/document";

interface IContractCategoryProps {
  contract: IContract;
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  hideTitle?: boolean;
  documents: IDocument[];
}

const CategoryCard = ({
  category,
  explanation,
}: {
  category?: string;
  explanation?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Grid container size={12} gap={2}>
      {category && <Typography variant="body1">{category}</Typography>}

      {explanation && <Typography variant="body1">{explanation}</Typography>}
    </Grid>
  );
};

function ContractCategory({ ...props }: IContractCategoryProps) {
  const { contract, mode, aiOutputs, documents } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Category")}
        </Typography>
      )}

      <CategoryCard
        category={contract?.category || contract?.procurement?.category || ""}
        explanation={contract?.procurement?.explanation}
      />

      {aiOutputs?.length > 0 && (
        <Box my={5}>
          <SimpleTreeView defaultExpandedItems={[`ai-output-items`]}>
            <TreeItem2
              itemId={`ai-output-items`}
              label={t("AI_suggestion", {
                count: aiOutputs?.length,
              })}
              slotProps={{
                content: {
                  style: {
                    paddingTop: 10,
                    paddingBottom: 10,
                  },
                },
              }}
            >
              {aiOutputs?.map((aiOutput) => {
                // if (aiOutput.id === latestAiOutput?.id) {
                //   // skip the latest ai output as it is already shown
                //   return null;
                // }

                const currentResult =
                  aiOutput.metadata?.structuralData?.procurement;

                if (!currentResult) {
                  return null;
                }

                const verificationRecord = verifications?.find(
                  (v) => v.field === "category" && v.aiOutputId === aiOutput.id
                );

                const document = documents.find(
                  (d) => d.id === aiOutput.documentId
                );

                return (
                  <TreeItem2
                    itemId={`${aiOutput.id}-prompts`}
                    label={
                      <Typography>
                        {aiOutput.processedAt
                          ? t("date.dateTime", {
                              date: new Date(aiOutput.processedAt),
                            })
                          : "No processing date"}
                      </Typography>
                    }
                    style={{
                      marginBottom: 5,
                      gap: 5,
                    }}
                    slotProps={{
                      content: {
                        style: {
                          paddingTop: 10,
                          paddingBottom: 10,
                        },
                      },
                    }}
                  >
                    <Box my={2} display="flex" flexDirection="column" gap={2}>
                      {document && documents?.length > 1 && (
                        <div>
                          <Chip
                            label={
                              document.type === "attachment"
                                ? document.name
                                : t("Main document")
                            }
                            color="primary"
                            size="small"
                            // icon={<AttachFile />}
                            variant="outlined"
                          />
                        </div>
                      )}

                      {verificationRecord && (
                        <VerifiedAISuggestion
                          verificationRecord={verificationRecord}
                        />
                      )}

                      <AISuggestion
                        contract={contract}
                        mode={mode}
                        isAiSuggestion={!verificationRecord}
                        collapsible={false}
                        isVerified={verificationRecord?.isCorrect !== undefined}
                        field="procurement"
                        value={currentResult}
                        aiInputId={aiOutput.aiInputId}
                        aiOutputId={aiOutput.id}
                        content={<CategoryCard {...currentResult} />}
                        processedAt={aiOutput?.processedAt}
                      />
                    </Box>
                  </TreeItem2>
                );
              })}
            </TreeItem2>
          </SimpleTreeView>
        </Box>
      )}
    </Box>
  );
}

export default ContractCategory;
