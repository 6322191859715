import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rgba } from "polished";

import {
  Box,
  CardActionArea,
  LinearProgress,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@mui/material";
import {
  Gauge,
  GaugeReferenceArc,
  GaugeValueArc,
  gaugeClasses,
  useGaugeState,
} from "@mui/x-charts/Gauge";
import { color, spacing } from "@mui/system";

import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import { grey } from "@mui/material/colors";
import { BorderLinearProgress } from "./PromptsSideBar";

const Typography = styled(MuiTypography)(spacing);

const featuredCardStyle = (props: any) => css`
  background: ${props.backgroundColor || props.theme.palette.primary.main};
  color: ${props.theme.palette.common.white};
`;

const Card = styled(MuiCard)<{
  featured?: boolean;
  backgroundColor?: string;
}>`
  position: relative;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

type PromptHeaderProps = {
  title: string;
  amount: number;
  totalAmount?: number;
  description?: string | React.ReactNode;
  backgroundColor?: string;
  icon?: React.ReactNode;
  variant?: "percentage" | "text" | "gauge";
};

const PromptHeader: React.FC<PromptHeaderProps> = ({
  title,
  amount,
  totalAmount,
  description,
  backgroundColor,
  icon,
  variant,
}) => {
  const [percentage, setPercentage] = React.useState(0);

  useEffect(() => {
    if (totalAmount) {
      setPercentage((amount / totalAmount) * 100);
    } else {
      setPercentage(amount);
    }
  }, [amount, totalAmount]);

  return (
    <Card
      // backgroundColor={backgroundColor}
      sx={{ width: "100%", display: "flex" }}
    >
      <CardContent
        sx={{
          color: backgroundColor === grey[200] ? "text.primary" : "inherit",
          width: "100%",
        }}
      >
        {icon}

        <Typography variant="h4" mt={4} mb={1}>
          {title}
        </Typography>

        <Typography variant="h3" mb={3}>
          <Box fontWeight="fontWeightBold" fontSize="2rem">
            {amount}
            {totalAmount && ` / ${totalAmount}`}

            {variant === "percentage" && (
              <Typography
                // color="primary"
                fontWeight="fontWeightBold"
                fontSize="2rem"
                display="inline"
              >
                %
              </Typography>
            )}

            {description && (
              <Typography variant="body2" color="textSecondary">
                {description}
              </Typography>
            )}
          </Box>
        </Typography>

        <BorderLinearProgress variant="determinate" value={percentage} />
      </CardContent>
    </Card>
  );
};

export default PromptHeader;
